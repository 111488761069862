import { HttpInterceptorFn } from '@angular/common/http';
import { UserToken } from '../authentication/models/user-token';

export const dtrInterceptor: HttpInterceptorFn = (req, next) => {
    // debugger;

    let token;
    if (typeof window !== 'undefined') {
        // Only run this code in the browser
        token = localStorage.getItem('token');
    }

    // const token = localStorage.getItem('token');

    // console.log(token);

    const clonedReq = req.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`,
        },
    });

    return next(clonedReq);
};
