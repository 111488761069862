import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    standalone: true,
    selector: 'app-editable-textbox',
    templateUrl: './editable-textbox.component.html',
    styleUrls: ['./editable-textbox.component.scss'],
    imports: [FormsModule],
})
export class EditableTextboxComponent implements OnInit {
    @Input() value: string;
    // 'Hello, I am an editable textarea. Hover and select me to see the editable textbox!';
    constructor() {}

    ngOnInit() {}
}
