import { Component, OnInit } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';
import {
    Router,
    RouterLink,
    RouterLinkActive,
    RouterModule,
} from '@angular/router';
import { ToggleService } from './toggle.service';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        NgScrollbarModule,
        MatExpansionModule,
        RouterLinkActive,
        RouterModule,
        RouterLink,
        NgClass,
        NgIf,
    ],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
    // isSidebarToggled
    isSidebarToggled = false;

    // isToggled
    isToggled = false;

    isLogged: boolean;

    constructor(
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private router: Router
    ) {
        this.toggleService.isSidebarToggled$.subscribe((isSidebarToggled) => {
            this.isSidebarToggled = isSidebarToggled;
        });
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }
    ngOnInit(): void {
        let token;
        if (typeof window !== 'undefined') {
            // Only run this code in the browser
            token = localStorage.getItem('token');
        }
        this.isLogged = token ? true : false;
    }

    // Burger Menu Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // Mat Expansion
    panelOpenState = false;

    logout(): void {
        console.log('logged out...');
        localStorage.clear();
        this.router.navigate(['/authentication/logout']);
        // routerLink="/authentication/logout"
    }
}
