<mat-card class="daxa-card starter-card mb-25 border-radius bg-white border-none d-block text-center">
    <mat-card-content>
        <img src="images/starter.png" alt="starter-image">
        <h3>
            Create Something Beautiful
        </h3>
        <a mat-button routerLink="/">
            Getting Started
        </a>
    </mat-card-content>
</mat-card>