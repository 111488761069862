<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        <span class="material-symbols-outlined cursor-pointer" title="Back" routerLink="/user-management-page">
            arrow_back_ios_new
        </span>&nbsp;Manage User
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Super Admin
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Management
        </li>
        <li class="breadcrumb-item position-relative cursor-pointer" routerLink="/user-management-page">
            Users
        </li>
        <li class="breadcrumb-item position-relative">
            Manage User
        </li>
    </ol>
</div>

<!-- Edit User -->
<mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <form [formGroup]="dtoForm">
            <div class="row">
                <!-- <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            User ID
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter user id
                            </mat-label>
                            <input matInput value="#ARP-1217">
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Full Name
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter full name
                            </mat-label>
                            <input matInput [formControlName]="'name'" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Email Address
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput [formControlName]="'email'" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Role
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Select role
                            </mat-label>
                            <mat-select [formControlName]="'role'" required>
                                <mat-option value="superadmin">
                                    Super Admin
                                </mat-option>
                                <mat-option value="siteadmin">
                                    Site Admin
                                </mat-option>
                                <mat-option value="user">
                                    User
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <!-- <div class="col-md-6 col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Description
                        </label>
                        <div class="NgxEditor__Wrapper">
                            <ng-container *ngIf="editor">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editor"></ngx-editor>
                            </ng-container>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-md-6 col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Image
                        </label>
                        <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                    </div>
                </div> -->

                <!-- <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Status
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Select status
                            </mat-label>
                            <mat-select [(value)]="statusSelected">
                                <mat-option value="option1">
                                    Active
                                </mat-option>
                                <mat-option value="option2">
                                    Deactive
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->


            </div>
           
        </form>
    </mat-card-content>
</mat-card>

<mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="btn-box">
            <button mat-button (click)="save()">
                Save Changes
            </button>
        </div>
    </mat-card-content>
</mat-card>