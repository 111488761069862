import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-site-workspaces',
    standalone: true,
    imports: [RouterLink, RouterOutlet],
    templateUrl: './site-workspaces.component.html',
    styleUrl: './site-workspaces.component.scss',
})
export class SiteWorkpsacesComponent {}
