import { NgIf } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { TemplateDto } from '../dto/template-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-list-template',
    standalone: true,
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        MatTableModule,
        MatPaginatorModule,
        NgIf,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    templateUrl: './list-template.component.html',
    styleUrl: './list-template.component.scss',
})
export class ListTemplateComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['name', 'description', 'column', 'row', 'action'];
    dataSource = new MatTableDataSource<TemplateDto>([]);
    selection = new SelectionModel<TemplateDto>(true, []);

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    // Search Filter
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    // isToggled
    isToggled = false;

    http = inject(HttpClient);

    templateList: TemplateDto[] = [];

    constructor(public themeService: CustomizerSettingsService) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        // debugger;
        this.http
            .get(`${environment.apiUrl}/api/v1/templates`)
            .subscribe((res: any) => {
                this.templateList = res;
                // console.log(this.templateList);
                this.dataSource.data = this.templateList;
            });
    }
}

// const ELEMENT_DATA: PeriodicElement[] = [
//     {
//         templateID: '#DTR-001',
//         name: '3x3 Workspace',
//         status: {
//             active: 'Active',
//             // deactive: 'Deactive',
//         },
//         action: {
//             edit: 'edit',
//             delete: 'delete',
//         },
//     },
//     {
//         templateID: '#DTR-002',
//         name: '4x4 Workspace',
//         status: {
//             active: 'Active',
//             // deactive: 'Deactive',
//         },
//         action: {
//             edit: 'edit',
//             delete: 'delete',
//         },
//     },
// ];

// export interface PeriodicElement {
//     templateID: string;
//     name: any;
//     status: any;
//     action: any;
// }
