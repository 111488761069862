import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { MatIconModule } from '@angular/material/icon';
import { WorkspaceCardContentComponent } from '../workspace-card-content/workspace-card-content.component';
import {
    CdkDrag,
    CdkDragDrop,
    CdkDragHandle,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { NgIf } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { concat, firstValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

import { v4 as uuid } from 'uuid';
import * as dataJson from './data.json';
import { MatSnackBar } from '@angular/material/snack-bar';

import { callOpenAiApi } from './openaiApi';
import { RequestData, ResponseData } from './apiTypes';

import * as openaiApiResponse from './openaiApiResponse.json';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { EditableTextboxComponent } from '../editable-textbox/editable-textbox.component';

export interface MissionContent {
    name: string;
}

export interface Tile {
    color: string;
    cols: number;
    rows: number;
    text: string;
}

@Component({
    selector: 'app-workspace',
    standalone: true,
    imports: [
        RouterLink,
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatIconModule,
        WorkspaceCardContentComponent,
        CdkDrag,
        CdkDragHandle,
        CdkDropList,
        CdkDropListGroup,
        MatChipsModule,
        NgIf,
        MatGridListModule,
        FormsModule,
        EditableTextboxComponent,
    ],
    templateUrl: './workspace.component.html',
    styleUrl: './workspace.component.scss',
})
export class WorkspaceComponent implements OnInit {
    // isToggled
    isToggled = false;
    // collapsed: boolean = false;
    collapsedA: boolean = false;
    collapsedB: boolean = false;
    collapsedC: boolean = false;
    cardContentData: any = [];
    collapsedLabelCard: boolean = false;

    params: any;
    workspace: any;
    matrixCols: number = 0;
    matrixRows: number = 0;
    labelMetrixs: any;
    aData: any;
    bData: any;
    cData: any;
    dDatas: any;
    workspaceLabelTree: any;

    isLoading = false; // สถานะการโหลด

    http = inject(HttpClient);
    private snackBar = inject(MatSnackBar);

    constructor(
        public themeService: CustomizerSettingsService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });

        this.activatedRoute.params.subscribe((params) => {
            // console.log(params)
            this.params = params;
        });

        this.init();
    }
    ngOnInit(): void {
        this.fetchData();
    }

    init() {
        this.cardContentData.visions = ['aaaa', 'bbbb', 'cccc'];
    }

    async fetchData() {
        // const dto$ = this.http.get(`${environment.apiUrl}/api/v1/workspaces/site/${this.siteId}/workspaces`);
        const workspace$ = this.http.get(
            `${environment.apiUrl}/api/v1/workspaces/${this.params.workspaceId}`
        );
        const workspace = await firstValueFrom(workspace$);

        // console.log(workspace)
        this.workspace = workspace;

        await this.bindData();
    }

    async bindData() {
        this.matrixCols = this.workspace.template.columns;
        this.matrixRows = this.workspace.template.rows;

        const tree = this.buildTree(this.workspace?.workspaceLabels);
        this.workspaceLabelTree = tree;
        // console.log(JSON.stringify(tree, null, 2));

        // A content
        this.aData = this.findLabel(tree, 'a', 'root');
        // console.log(this.aData)

        // B content
        this.bData = this.findLabel(tree, 'b', 'root');

        // C content
        this.cData = this.findLabel(tree, 'c', 'root');

        // D content
        const bodyLabels = this.filterLabels(tree, 'd', 'root');
        // console.log(bodyLabels)

        const matrixs = this.toMatrixWithColumns(
            bodyLabels.sort(
                (a: { displayIndex: number }, b: { displayIndex: number }) =>
                    a.displayIndex < b.displayIndex ? -1 : 1
            ),
            this.matrixCols
        );
        this.labelMetrixs = matrixs;
        // console.log(this.labelMetrixs)
    }

    genPrompt(genType: string): string {
        let prompt = new String('จากอุตสาหกรรม '); // {workspacename} ช่วยใส่ชื่องานตาม หัวข้อดังต่อไปนี้, หัวข้อที่ 1 {A}, หัวข้อที่ 2 {B}, ... หัวข้อที่ 12 (matrix 3,3) โดยแต่ละหัวข้อให้ใส่มา 5 เรื่อง'
        const space = ' ';
        const comma = ',';

        switch (genType) {
            case 'Gen AI - All':
                // Workspace name
                prompt = prompt.concat(this.workspace.name).concat(space);
                prompt = prompt
                    .concat('ช่วยใส่ชื่องานตามหัวข้อดังต่อไปนี้')
                    .concat(comma);
                // A content
                prompt = prompt
                    .concat('หัวข้อที่ 1')
                    .concat(space)
                    .concat(this.aData.labelName)
                    .concat(comma);
                // B content
                prompt = prompt
                    .concat('หัวข้อที่ 2')
                    .concat(space)
                    .concat(this.bData.labelName)
                    .concat(comma);
                // C content
                prompt = prompt
                    .concat('หัวข้อที่ 3')
                    .concat(space)
                    .concat(this.cData.labelName)
                    .concat(comma);

                // D content
                var i: number;
                var idx: number = 4;
                const bodyLabels = this.filterLabels(
                    this.workspace?.workspaceLabels,
                    'd',
                    'root'
                );
                const sortedBodyLabels = bodyLabels.sort(
                    (
                        a: { displayIndex: number },
                        b: { displayIndex: number }
                    ) => (a.displayIndex < b.displayIndex ? -1 : 1)
                );
                sortedBodyLabels.forEach(function (label: any) {
                    prompt = prompt
                        .concat('หัวข้อที่ ')
                        .concat(idx.toString())
                        .concat(space)
                        .concat(label.labelName)
                        .concat(comma);
                    idx++;
                });

                prompt = prompt
                    .concat(space)
                    .concat('โดยแต่ละหัวข้อให้ใส่มา 5 เรื่อง')
                    .concat(space);
                prompt = prompt.concat('ในรูปแบบ json');
                break;
            case 'Gen AI -  Top to Bottom':
                break;
            case 'Gen AI -  Bottom to Top':
                break;
        }
        return prompt.toString();
    }

    async callAi(prompt: string): Promise<any> {
        // debugger;
        const requestData: RequestData = {
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'user', content: prompt }],
            max_tokens: 4000,
        };

        console.log(prompt);
        try {
            const responseData = await callOpenAiApi(requestData);

            // console.log("Response from OpenAI:", responseData.choices[0].message.content);
            return responseData.choices[0].message.content;
        } catch (error) {
            debugger;
            console.error(error);
        }
    }

    parseJsonToArray(jsonString: string): any[] {
        try {
            const parsed = JSON.parse(jsonString);

            // เช็คว่าเป็น object หรือไม่
            if (Array.isArray(parsed)) {
                return parsed; // ถ้าเป็น array ให้คืนค่าเลย
            } else if (typeof parsed === 'object' && parsed !== null) {
                return [parsed]; // ถ้าเป็น object ให้ใส่ใน array
            } else {
                throw new Error('Invalid JSON format'); // ถ้าไม่ใช่ object หรือ array ให้ throw error
            }
        } catch (error) {
            debugger;
            console.error('Error parsing JSON:', error);
            return []; // คืนค่าเป็น array ว่างถ้ามีข้อผิดพลาด
        }
    }

    async bindAiData(aiData: any) {
        console.log(aiData);
        try {
            // debugger;
            const parseAiData = this.parseJsonToArray(aiData);
            if (parseAiData) {
                // A,B,C content
                const contents: string[] = ['a', 'b', 'c'];

                let headerIdx: number = 0;
                let bodyIdx: number = 1;

                let now: Date = new Date();

                // const bodyLabels = this.filterLabels(this.workspaceLabelTree, 'd', 'root');
                // const sortedBodyLabels = bodyLabels.sort((a: { displayIndex: number; }, b: { displayIndex: number; }) => (a.displayIndex < b.displayIndex ? -1 : 1));

                Object.values(parseAiData[0]).forEach((labels: any) => {
                    // Workspace Headers [A(0), B(1), C(2)]
                    if (headerIdx <= 2) {
                        let displayIndex: number = 1;

                        const aContent = this.findLabel(
                            this.workspaceLabelTree,
                            contents[headerIdx],
                            'root'
                        );

                        labels.forEach((element: string) => {
                            // console.log(element)
                            aContent.children.push({
                                id: uuid(),
                                parentId: aContent.id,
                                labelType: contents[headerIdx],
                                labelName: element,
                                contentType: aContent.contentType,
                                isDTCard: aContent.isDTCard,
                                isNullable: aContent.isNullable,
                                displayIndex: displayIndex++,
                                createdAt: now.toUTCString(),
                                updatedAt: now.toUTCString(),
                                workspaceId: aContent.workspaceId,
                            });
                        });

                        headerIdx++;
                    } else {
                        // Workspace Body [D]
                        const dContent = this.findBodyLabel(
                            this.workspaceLabelTree,
                            bodyIdx
                        );
                        let displayIndex: number = 1;

                        labels.forEach((element: string) => {
                            // console.log(element)
                            dContent.children.push({
                                id: uuid(),
                                parentId: dContent.id,
                                labelType: contents[headerIdx],
                                labelName: element,
                                contentType: dContent.contentType,
                                isDTCard: dContent.isDTCard,
                                isNullable: dContent.isNullable,
                                displayIndex: displayIndex++,
                                createdAt: now.toUTCString(),
                                updatedAt: now.toUTCString(),
                                workspaceId: dContent.workspaceId,
                            });
                        });
                        bodyIdx++;
                    }
                });

                // D content
                /* สำหรับอ่านค่า data.json
                const bodyLabels  = this.filterLabels(this.workspaceLabelTree,'d', 'root');
                const sortedBodyLabels = bodyLabels.sort((a: { displayIndex: number; }, b: { displayIndex: number; }) => (a.displayIndex < b.displayIndex ? -1 : 1));
                sortedBodyLabels.forEach((dContent: any) => {
                    const aData = aiData[idxAi++];
        
                    // console.log(dContent)
        
                    Object.values(aData).forEach((labels: any) => {
                        let idx: number = 1;
                        let now: Date = new Date();
                        labels.forEach((element: string) => {
                            // console.log(element)
                            dContent.children.push(
                                {
                                    "id": uuid(),
                                    "parentId": dContent.id,
                                    "labelType": "a",
                                    "labelName": element,
                                    "contentType": dContent.contentType,
                                    "isDTCard": dContent.isDTCard,
                                    "isNullable": dContent.isNullable,
                                    "displayIndex": idx++,
                                    "createdAt": now.toUTCString(),
                                    "updatedAt": now.toUTCString(),
                                    "workspaceId": dContent.workspaceId
                                }
                            );
                        });
        
                    });
                });
                */
            } else {
                this.snackBar.open('Gen AI Failed!', 'OK', {
                    duration: 3000,
                    panelClass: ['notification-error'],
                });
            }
            this.isLoading = false;
        } catch (error) {
            debugger;
            console.error(error);
        }
    }

    doGenAi(genType: string) {
        Swal.fire({
            title: `Do you want to<br /> ${genType}?`,
            icon: 'question',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, with append',
            denyButtonText: `Yes, with replace`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.isLoading = true; // ตั้งค่าให้ loading เป็น true
                const dialogRef = this.dialog.open(LoadingDialogComponent, {
                    disableClose: true, // ปิดการปิด dialog ด้วยการคลิกนอก dialog
                    panelClass: 'loading-dialog-panel', // สามารถใช้ class เพิ่มเติมสำหรับ styling
                });

                // Swal.fire(JSON.stringify(this.genPrompt(genType)), "", "success");

                const aiResponse = await this.callAi(this.genPrompt(genType));

                // await this.delay(1000);

                await this.bindAiData(aiResponse);

                dialogRef.close();

                // this.snackBar.open("Gen AI Successful", "OK", {
                //     duration: 3000,
                //     panelClass: ['notification-error'],
                // });
            } else if (result.isDenied) {
                this.isLoading = true; // ตั้งค่าให้ loading เป็น true
                const dialogRef = this.dialog.open(LoadingDialogComponent, {
                    disableClose: true, // ปิดการปิด dialog ด้วยการคลิกนอก dialog
                    panelClass: 'loading-dialog-panel', // สามารถใช้ class เพิ่มเติมสำหรับ styling
                });

                const contents: string[] = ['a', 'b', 'c'];
                // A content
                contents.forEach((element: any) => {
                    const item = this.findLabel(
                        this.workspaceLabelTree,
                        element,
                        'root'
                    );
                    item?.children.splice(0, item?.children.length);
                });
                // D content
                const dItems = this.filterLabels(
                    this.workspaceLabelTree,
                    'd',
                    'root'
                );
                // console.log(dItems)
                dItems.forEach((element: any) => {
                    element?.children.splice(0, element?.children.length);
                });

                // await this.delay(1000);

                const aiResponse = await this.callAi(this.genPrompt(genType));

                await this.bindAiData(aiResponse);

                dialogRef.close();
            }
        });
    }

    addDTCard(obj: any, event: any) {
        //console.log("Filter: ", (event?.target as HTMLInputElement).value);

        const name: string = (event?.target as HTMLInputElement).value;
        console.log(name);

        if (obj.children) {
            let now: Date = new Date();
            obj.children.push({
                id: uuid(),
                parentId: obj.id,
                labelType: 'a',
                labelName: name,
                contentType: obj.contentType,
                isDTCard: obj.isDTCard,
                isNullable: obj.isNullable,
                displayIndex: obj.children.length + 1,
                createdAt: now.toUTCString(),
                updatedAt: now.toUTCString(),
                workspaceId: obj.workspaceId,
            });

            (event?.target as HTMLInputElement).value = '';
        }
    }

    removeLabels(items: any, removeItem: any) {
        items.forEach((item: any, index: any) => {
            // console.log(item)
            //   if(item === removeItem)
            items.splice(index, 1);
        });
    }

    removeDTCard(data: any, id: string) {
        // console.log(id)
        // console.log(this.workspace.workspaceLabels.length)
        // const label = this.findLabelById(id);
        // console.log(label)
        // this.workspace.workspaceLabels.forEach((item: any, index: any) => {
        //     // console.log(item)
        //     // console.log(index)
        //     if (item.id == id) {
        //         console.log(item)
        //         this.workspace.workspaceLabels.splice(index, 1);
        //         console.log(this.workspace.workspaceLabels.length)
        //     }
        // });
        data.children.forEach((item: any, index: any) => {
            // console.log(item)
            // console.log(index)
            if (item.id == id) {
                // console.log(item)
                data.children.splice(index, 1);
                // console.log(this.workspace.workspaceLabels.length)
            }
        });
    }

    delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    buildTree(items: any[], parentId: string = 'root'): any[] {
        // กรองข้อมูลที่ตรงกับ parentId ที่ส่งเข้ามา (default คือ 'root')
        return items
            .filter((item) => item.parentId === parentId)
            .map((item) => ({
                ...item, // เก็บข้อมูลของ item เดิม
                children: this.buildTree(items, item.id), // เรียก recursive เพื่อหา children ของ item ปัจจุบัน
            }));
    }

    toMatrixWithColumns(arr: any[], a: number): any[][] {
        if (a <= 0) {
            throw new Error("Number of columns 'a' must be greater than 0.");
        }

        const matrix: any[][] = [];
        for (let i = 0; i < arr.length; i += a) {
            matrix.push(arr.slice(i, i + a));
        }
        return matrix;
    }

    findLabel(items: any, labelType: string, parentId: string): any {
        const findState = items.find(function (item: {
            labelType: string;
            parentId: string;
        }) {
            return item.labelType == labelType && item.parentId == parentId;
        });
        // console.log(findState)

        return findState;
    }

    findLabelById(id: string): any {
        const findState = this.workspace?.workspaceLabels.find(function (item: {
            id: string;
        }) {
            return item.id == id;
        });

        return findState;
    }

    findBodyLabel(items: any, idx: number): any {
        const findState = items.find(function (item: {
            labelType: string;
            displayIndex: number;
        }) {
            return item.labelType == 'd' && item.displayIndex == idx;
        });

        return findState;
    }

    filterLabels(items: any, labelType: string, parentId: string): any {
        const findState = items.filter(function (item: {
            labelType: string;
            parentId: string;
        }) {
            return item.labelType == labelType && item.parentId == parentId;
        });
        // console.log(findState)

        return findState;
    }

    // Chips Drag & Drop
    missions: MissionContent[] = [
        { name: 'ขายกาแฟให้มีรายได้ 500,000 บาท' },
        { name: 'มีลูกค้าเข้าร้านไ' },
        { name: 'มีลูกค้าเข้าร้านไม่น้อยกว่า ' },
        { name: 'มีลูกค้าเข้าร้านไม่น้อยกว่า 1,000 คน' },
        { name: 'มีลูกค้า' },
    ];

    drop(event: CdkDragDrop<any[]>, items: any[]) {
        console.log(event);
        moveItemInArray(items, event.previousIndex, event.currentIndex);
    }

    onCollapseExpand(event: any) {
        console.log(event);
        event = !event;
    }

    async updateWorkspace(name: string): Promise<boolean> {
        const dto$ = this.http.patch(
            `${environment.apiUrl}/api/v1/workspaces/${this.workspace.id}`,
            { name: name }
        );
        const dto = await firstValueFrom(dto$);

        return dto ? true : false;
    }

    async updateLabel(id: string, fields: any): Promise<boolean> {
        const dto$ = this.http.patch(
            `${environment.apiUrl}/api/v1/workspaces/labels/${id}`,
            fields
        );
        const dto = await firstValueFrom(dto$);

        return dto ? true : false;
    }

    async onEditWorkspaceName() {
        Swal.fire({
            title: 'Update!',
            text: 'Enter new workspace name.',
            input: 'text',
            inputValue: this.workspace.name,
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                //console.log("Result: " + result.value);
                if (await this.updateWorkspace(result.value)) {
                    this.workspace.name = result.value;
                    this.snackBar.open(
                        'Update workspace name successful.',
                        'OK',
                        {
                            duration: 3000,
                            panelClass: ['notification-error'],
                        }
                    );
                }
            }
        });
    }

    // async onEditLabelName(data: any) {

    //     const label = await this.findLabelById(data.id);

    //     Swal.fire({
    //         title: "Update!",
    //         text: "Enter new label name.",
    //         input: 'text',
    //         inputValue: label.labelName,
    //         showCancelButton: true
    //     }).then(async (result) => {
    //         if (result.value) {
    //             //console.log("Result: " + result.value);
    //             const fields = { labelName: result.value }
    //             if (await this.updateLabel(data.id, fields)) {

    //                 data.labelName = result.value;
    //                 // Swal.fire({
    //                 //     title: "Update successfully",
    //                 //     text: "Your label name has been updated.",
    //                 //     icon: "success"
    //                 // }).then(()=> {
    //                 //     data.labelName = result.value;
    //                 // });
    //             }
    //         }
    //     });
    // }

    async onEditLabelName(labelType: string, data: any) {
        // const label = await this.findLabelById(data.id);

        Swal.fire({
            title: 'Update!',
            text: 'Enter new label name.',
            input: 'text',
            inputValue: data.labelName,
            showCancelButton: true,
        }).then(async (result) => {
            if (result.value) {
                //console.log("Result: " + result.value);
                const fields = { labelName: result.value };
                if (await this.updateLabel(data.id, fields)) {
                    data.labelName = result.value;
                    this.snackBar.open('Update label name successful.', 'OK', {
                        duration: 3000,
                        panelClass: ['notification-error'],
                    });
                }
            }
        });
    }

    // drop(event: CdkDragDrop<string[]>) {
    //     console.log(event);
    //     if (event.previousContainer === event.container) {
    //         moveItemInArray(
    //             event.container.data,
    //             event.previousIndex,
    //             event.currentIndex
    //         );
    //     } else {
    //         transferArrayItem(
    //             event.previousContainer.data,
    //             event.container.data,
    //             event.previousIndex,
    //             event.currentIndex
    //         );
    //     }
    // }

    openDTCard(id: String) {
        console.log(id);
    }
}
