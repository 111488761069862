import { Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgxEditorModule, Editor, Toolbar } from 'ngx-editor';
import { MatCheckbox } from '@angular/material/checkbox';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TopicDto } from '../dto/topic-dto';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2'
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-manage-topic',
    standalone: true,
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxEditorModule,
        NgIf,
        MatCheckbox,
    ],
    templateUrl: './manage-topic.component.html',
    styleUrl: './manage-topic.component.scss',
})
export class ManageTopicComponent implements OnInit {
    dto: TopicDto;
    dtoForm: FormGroup;
    formMode: 'add' | 'update';
    id!: string | null;

    // Text Editor
    editor!: Editor | null; // Make it nullable
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // Initialize the editor only in the browser
            this.editor = new Editor();
        }
        // debugger;
        this.id  = this.activatedRoute.snapshot.paramMap.get('id');

        this.formMode = this.id ? 'update' : 'add';        

        this.dtoForm = this.formBuilder.group({
            // id: [''],
            name: ['', [Validators.required]],
            topicType: ['default', [Validators.required]],
            description: [''],
            // isActive: [true]
        });

        if(this.id) {
            this.fetchData();
        } else {
            this.dtoForm.patchValue({ isDTCard: true})
        }

        // this.http
        // .get(`${environment.apiUrl}/api/v1/labels/${id}`)
        // .subscribe((res: any) => {
        //     this.dto = res;
        //     // console.log(this.dto);
        //     this.dtoForm.patchValue(this.dto)
        // }, err => {
        //     console.log(err)
        // });
        
    }

    

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId) && this.editor) {
            this.editor.destroy();
        }
    }

    http = inject(HttpClient);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private router: Router
    ) {}

    // File Uploader
    public multiple: boolean = false;

    // Select Value
    statusSelected = 'option1';
    labelClassSelected = 'option1';
    contentTypeSelected = 'option1';
    isNullableSelected = 'option1';
    isDTCardSelected = 'option2';

    async fetchData() {
        const dto$ = this.http.get(`${environment.apiUrl}/api/v1/topics/${this.id}`);
        const dto = await lastValueFrom(dto$);
        
        // console.log(dto)
        this.dtoForm.patchValue(dto);
    }

    async create(): Promise<boolean> {
        // this.dtoForm.removeControl('id')
        // this.dtoForm.removeControl('isActive')

        const dtoValue: TopicDto = this.dtoForm.getRawValue();
        // console.log(dtoValue)
        const dto$ = this.http.post(`${environment.apiUrl}/api/v1/topics`, dtoValue);

        // this.http.post<any>(`${environment.apiUrl}/api/v1/labels`, dtoValue).subscribe({
        //     next: data => {
        //         console.log(`Result: ` + JSON.stringify(data));
        //     },
        //     error: error => {
        //         // this.errorMessage = error.message;
        //         console.error('There was an error!', error);
        //     }
        // })

        const dto = await firstValueFrom(dto$)
        // .then((value) => {
        //     //console.log(`Result: ` + value);
        //     return true;
        //   }).catch((err)=> {
        //     console.log(err)
        //     this.snackBar.open(err.error.message, "DISMISS");
        //   });

        // console.log(dto)
        
        return dto ? true : false;
    }

    async update(): Promise<boolean> {
        // this.dtoForm.removeControl('id')
        // this.dtoForm.removeControl('isActive')

        const dtoValue: TopicDto = this.dtoForm.getRawValue();

        const dto$ = this.http.put(`${environment.apiUrl}/api/v1/topics/${this.id}`, dtoValue);
        const dto = await firstValueFrom(dto$);
        // .then((value) => {
        //     // console.log(`Result: ` + value);
        //     return true;
        //   }).catch((err)=> {
        //     console.log(err)
        //     this.snackBar.open(err.error.message, "DISMISS");
        //   });
        
        return dto ? true : false;
    }

    async save() {
        // console.log(this.dtoForm.valid)

        if(this.dtoForm.valid) {
            if(this.formMode == 'add') {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // const dtoForSave = this.dtoForm.getRawValue();
                        // console.log(dtoForSave)
                        if (await this.create()) {
                            Swal.fire({
                                title: "Saved successfully",
                                text: "Your data has been saved.",
                                icon: "success"
                            }).then(async(result) => {
                                if(result) {
                                    this.router.navigate(["topic-management-page/topics"]);
                                }
                            });
                        }
                    }
                });
            } else {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const dtoForSave = this.dtoForm.getRawValue();
                        // console.log(dtoForSave)
                        if (await this.update()) {
                            Swal.fire({
                                title: "Update successfully",
                                text: "Your data has been updated.",
                                icon: "success"
                            });
                        }
                    }
                });
            }
        }
    }
}
