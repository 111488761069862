<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Compose
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apps
        </li>
        <li class="breadcrumb-item position-relative">
            Email
        </li>
        <li class="breadcrumb-item position-relative">
            Compose
        </li>
    </ol>
</div>

<!-- Compose -->
<div class="row">
    <div class="col-lg-3 col-md-12">
        <app-sidebar />
    </div>
    <div class="col-lg-9 col-md-12">
        <mat-card
            class="daxa-card email-compose-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        New Message
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <div class="info-buttons-list d-flex align-items-center">
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                refresh
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                print
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                report_gmailerrorred
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                delete
                            </i>
                        </button>
                        <button type="button" class="text-body" mat-button [matMenuTriggerFor]="emailHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button>
                        <mat-menu #emailHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item>
                                Recent
                            </button>
                            <button mat-menu-item>
                                Unread
                            </button>
                            <button mat-menu-item>
                                Mark All Read
                            </button>
                            <button mat-menu-item>
                                Spam
                            </button>
                            <button mat-menu-item>
                                Delete All
                            </button>
                        </mat-menu>
                    </div>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    To
                                </label>
                                <mat-form-field>
                                    <mat-label>To</mat-label>
                                    <mat-select [formControl]="emails" multiple>
                                        @for (email of emailList; track email) {
                                            <mat-option [value]="email">
                                                {{email}}
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Cc
                                </label>
                                <mat-form-field>
                                    <mat-label>Cc</mat-label>
                                    <mat-select [formControl]="emails" multiple>
                                        @for (email of emailList; track email) {
                                            <mat-option [value]="email">
                                                {{email}}
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Bcc
                                </label>
                                <mat-form-field>
                                    <mat-label>Bcc</mat-label>
                                    <mat-select [formControl]="emails" multiple>
                                        @for (email of emailList; track email) {
                                            <mat-option [value]="email">
                                                {{email}}
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Subject
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Write your subject
                                    </mat-label>
                                    <input matInput placeholder="E.g. Hand Watch">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Your Email
                                </label>
                                <div class="NgxEditor__Wrapper">
                                    <ng-container *ngIf="editor">
                                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                        <ngx-editor [editor]="editor"></ngx-editor>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box d-flex align-items-center">
                        <button mat-button class="btn">
                            Send
                        </button>
                        <div class="buttons-list d-flex align-items-center">
                            <button mat-button class="text-body">
                                <i class="material-symbols-outlined">
                                    sentiment_satisfied
                                </i>
                            </button>
                            <button mat-button class="text-body">
                                <i class="material-symbols-outlined">
                                    attach_file
                                </i>
                            </button>
                            <button mat-button class="text-body">
                                <i class="material-symbols-outlined">
                                    image
                                </i>
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>