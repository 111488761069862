<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Template
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Super Admin
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Management
        </li>
        <li class="breadcrumb-item position-relative cursor-pointer" routerLink="/template-management-page">
            Templates
        </li>
        <li class="breadcrumb-item position-relative">
            Manage Template
        </li>
    </ol>
</div>
<form [formGroup]="templateForm">
    <mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
        <mat-card-header>
            <mat-card-title>
                <h5 class="title mt-0">
                    Template Information
                </h5>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="material-symbols-outlined cursor-pointer"
                    title="{{collapsedInformation == false ? 'Collapse' : 'Expand'}}"
                    (click)="collapsedInformation=!collapsedInformation">
                    {{collapsedInformation == false ? 'collapse' : 'expand'}}_content
                </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="!collapsedInformation">

            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Name
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Template Name
                                    </mat-label>
                                    <input matInput [formControlName]="'name'" required>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Description
                                </label>
                                <mat-form-field class="textarea">
                                    <mat-label>
                                        Write your description
                                    </mat-label>
                                    <textarea matInput [formControlName]="'description'" placeholder="..."></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card class="daxa-card edit-product-card mb-25 border-radius bg-white border-none d-block">
        <mat-card-header>
            <mat-card-title>
                <h5 class="title mt-0">
                    ABC Topic
                </h5>
            </mat-card-title>

            <mat-card-subtitle>
                <span class="material-symbols-outlined cursor-pointer"
                    title="{{collapsedHeader == false ? 'Collapse' : 'Expand'}}"
                    (click)="collapsedHeader=!collapsedHeader">
                    {{collapsedHeader == false ? 'collapse' : 'expand'}}_content
                </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="!collapsedHeader">
            <div formArrayName="topics">
                <div *ngFor="let topic of topicFormArray.controls; let idx = index" [formGroupName]="idx">
                    <div *ngIf="['A','B','C'].includes(getControlValue(topic,'topicRef'))" class="row">
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <fieldset>
                                    <legend> {{getControlValue(topic,'topicRef') | uppercase}} Topic</legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field [style.background-color]="getControlValue(topic,'bgColor')"
                                                class="mt-10">
                                                <mat-label>Select topic</mat-label>
                                                <input type="text" matInput [formControlName]="'topicName'"
                                                    [style.color]="getControlValue(topic,'fontColor')">
                                                <!--[formControl]="topicControl" <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">[matAutocomplete]="auto"
                                                            @for (option of filteredOptions | async; track option) {
                                                            <mat-option [value]="option">{{option.name}}</mat-option>
                                                            }
                                                        </mat-autocomplete> -->
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6 mt-10">
                                                    <mat-form-field>
                                                        <mat-label>
                                                            Background Color
                                                        </mat-label>
                                                        <input matInput [formControlName]="'bgColor'"
                                                            (change)="bgColorChange(getControlValue(topic,'id'), $event.target)"
                                                            required>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-6 mt-10">
                                                    <mat-form-field>
                                                        <mat-label>
                                                            Font Color
                                                        </mat-label>
                                                        <input matInput [formControlName]="'fontColor'"
                                                            (change)="fontColorChange(getControlValue(topic,'id'), $event.target)"
                                                            required>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"><br /></div>
                                        <div class="col-md-6">
                                            <mat-checkbox [formControlName]="'canGenAi'">Can Generate AI</mat-checkbox>
                                            <mat-checkbox [formControlName]="'isDTCard'">Is DTCard</mat-checkbox>
                                            <mat-checkbox [formControlName]="'isDisplay'">Display Value</mat-checkbox>
                                            <mat-checkbox [formControlName]="'isNull'">Is Null</mat-checkbox>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <!-- <mat-card-actions class="ps-15 mt-10 mb-10">

        </mat-card-actions> -->
    </mat-card>

    <mat-card class="daxa-card edit-product-card mb-25 border-radius bg-white border-none d-block">
        <mat-card-header>
            <mat-card-title>
                <h5 class="title mt-0">
                    Body Topic
                </h5>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="material-symbols-outlined cursor-pointer"
                    title="{{collapsedBody == false ? 'Collapse' : 'Expand'}}" (click)="collapsedBody=!collapsedBody">
                    {{collapsedBody == false ? 'collapse' : 'expand'}}_content
                </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="!collapsedBody">
            <!-- <form> -->
            <div class="row">
                <div class="col-lg-12 col-xxxl-12">

                    <div class="row">
                        <div class="col-lg-6 col-xxxl-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Columns
                                </label>
                                <mat-form-field class="mt-10">
                                    <mat-label>
                                        Enter columns
                                    </mat-label>
                                    <input matInput [(ngModel)]="bodyColumns"
                                        (ngModelChange)="onBodyColumnMatrixChange()"
                                        [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xxxl-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Rows
                                </label>
                                <mat-form-field class="mt-10">
                                    <mat-label>
                                        Enter rows
                                    </mat-label>
                                    <input matInput [(ngModel)]="bodyRows" (ngModelChange)="onBodyRowMatrixChange()"
                                        [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            @for (idx of bodyMatrixSize; track idx) {
                            <div class="mb-25">

                                <fieldset>
                                    <legend> Topic ({{idx}})</legend>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <mat-form-field class="mt-10">
                                                <mat-label>
                                                    Select topic
                                                </mat-label>
                                                <mat-select [(value)]="labelSelectedOption3">
                                                    @for (label of labels; track label) {
                                                    <mat-option value="label.value">
                                                        {{label.name}}
                                                    </mat-option>
                                                    }
                                                </mat-select>
                                            </mat-form-field>

                                        </div>

                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6 mt-10">
                                                    <mat-form-field>
                                                        <mat-label>
                                                            Background Color
                                                        </mat-label>
                                                        <input matInput required>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-6 mt-10">
                                                    <mat-form-field>
                                                        <mat-label>
                                                            Font Color
                                                        </mat-label>
                                                        <input matInput required>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6"><br /></div>
                                        <div class="col-md-6">
                                            <mat-checkbox>Can Generate AI</mat-checkbox>
                                            <mat-checkbox>Is DTCard</mat-checkbox>
                                            <mat-checkbox>Display Value</mat-checkbox>
                                            <mat-checkbox>Is Null</mat-checkbox>
                                        </div>
                                    </div>

                                </fieldset>
                            </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">

            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="daxa-card edit-product-card mb-25 border-radius bg-white border-none d-block">
        <mat-card-header>
            <mat-card-title>
                <h5 class="title mt-0">
                    DT Card Topic
                </h5>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="material-symbols-outlined cursor-pointer"
                    title="{{collapsedDTCard == false ? 'Collapse' : 'Expand'}}"
                    (click)="collapsedDTCard=!collapsedDTCard">
                    {{collapsedDTCard == false ? 'collapse' : 'expand'}}_content
                </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="!collapsedDTCard">

            <div class="row">
                <div class="col-lg-12 col-xxxl-12">

                    <div class="row">
                        <div class="col-lg-6 col-xxxl-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Matrix
                                </label>
                                <mat-form-field class="mt-10">
                                    <mat-label>
                                        Select matrix
                                    </mat-label>
                                    <mat-select [(ngModel)]="selectedMatrix" (ngModelChange)="onMatrixChange()"
                                        [ngModelOptions]="{standalone: true}">
                                        <mat-option value="1">
                                            4x4
                                        </mat-option>
                                        <mat-option value="2">
                                            5x5
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-xxxl-12">
                            @for (idx of dtCardMatrixSize; track idx)
                            {
                            <div class="mb-25">
                                <fieldset>
                                    <legend> Topic ({{idx}})</legend>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <mat-form-field class="mt-10">
                                                <mat-label>
                                                    Select topic
                                                </mat-label>
                                                <mat-select [(value)]="labelSelectedOption3">
                                                    @for (label of labels; track label) {
                                                    <mat-option value="label.value">
                                                        {{label.name}}
                                                    </mat-option>
                                                    }
                                                </mat-select>
                                            </mat-form-field>

                                        </div>

                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <mat-form-field class="mt-10">
                                                        <mat-label>
                                                            Background Color
                                                        </mat-label>
                                                        <input matInput required>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-6">
                                                    <mat-form-field class="mt-10">
                                                        <mat-label>
                                                            Font Color
                                                        </mat-label>
                                                        <input matInput required>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">

                                            <mat-form-field class="mt-10">
                                                <mat-label>
                                                    Select content type
                                                </mat-label>
                                                <mat-select>
                                                    <mat-option value="text">
                                                        Text
                                                    </mat-option>
                                                    <mat-option value="multipleText">
                                                        Multiple Text
                                                    </mat-option>
                                                    <mat-option value="date">
                                                        Date
                                                    </mat-option>
                                                    <mat-option value="number">
                                                        Number
                                                    </mat-option>
                                                    <mat-option value="progress">
                                                        Progress
                                                    </mat-option>
                                                    <mat-option value="textarea">
                                                        Textarea
                                                    </mat-option>
                                                    <mat-option value="grid">
                                                        Grid
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>
                                        <div class="col-md-6">
                                            <mat-checkbox>Can Generate AI</mat-checkbox>
                                            <mat-checkbox>Is DTCard</mat-checkbox>
                                            <mat-checkbox>Display Value</mat-checkbox>
                                            <mat-checkbox>Is Null</mat-checkbox>
                                        </div>
                                    </div>

                                </fieldset>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

            </div>
        </mat-card-content>
    </mat-card>

    <!-- <app-template3x3 /> -->

    <mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
        <mat-card-header>
            <mat-card-title>
                <h5 class="title mt-0">
                    Prompt AI
                </h5>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="material-symbols-outlined cursor-pointer"
                    title="{{collapsedPrompt == false ? 'Collapse' : 'Expand'}}"
                    (click)="collapsedPrompt=!collapsedPrompt">
                    {{collapsedPrompt == false ? 'collapse' : 'expand'}}_content
                </span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="!collapsedPrompt">

            <div class="row">
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Gen All
                            <span class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="genPromptAll()">
                                border_color
                            </span>
                        </label>
                        <mat-form-field class="textarea">
                            <mat-label>
                                Prompt AI...
                            </mat-label>
                            <textarea matInput [formControlName]="'promptAiGenAll'" placeholder="..."></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Gen Top to Bottom
                            <span class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="genPromptTopToBody()">
                                border_color
                            </span>
                        </label>
                        <mat-form-field class="textarea">
                            <mat-label>
                                Prompt AI...
                            </mat-label>
                            <textarea matInput [formControlName]="'promptAiGenTopToBody'" placeholder="..."></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Gen Bottom to Top
                            <span class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="genPromptBodyToTop()">
                                border_color
                            </span>
                        </label>
                        <mat-form-field class="textarea">
                            <mat-label>
                                Prompt AI...
                            </mat-label>
                            <textarea matInput [formControlName]="'promptAiGenBodyToTop'" placeholder="..."></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Gen DT Card
                            <span class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="genPromptDtCard()">
                                border_color
                            </span>
                        </label>
                        <mat-form-field class="textarea">
                            <mat-label>
                                Prompt AI...
                            </mat-label>
                            <textarea matInput [formControlName]="'promptAiGenDTCard'" placeholder="..."></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

</form>

<mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="btn-box">
            <button mat-button (click)="save()">
                Save Changes
            </button>
        </div>
    </mat-card-content>
</mat-card>