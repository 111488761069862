import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { MatTabsModule } from '@angular/material/tabs';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { WorkspaceDto } from '../dto/workspace-dto';
import { environment } from '../../../../environments/environment';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-site',
    standalone: true,
    imports: [
        RouterLink,
        RouterOutlet,
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLinkActive,
        MatTabsModule,
        FormsModule,
        MatSelectModule,
    ],
    templateUrl: './site.component.html',
    styleUrl: './site.component.scss',
})
export class SiteComponent {
    // isToggled
    isToggled = false;

    http = inject(HttpClient);

    site: any;
    workspaces: any;
    templates: any;
    siteId!: string | null;

    constructor(
        public themeService: CustomizerSettingsService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });

        this.siteId  = this.activatedRoute.snapshot.paramMap.get('id');
        // if(!this.siteId) {
        //     this.router.navigate(["sites", '5e497418-fbd3-42e9-9a5e-bd56017fdb34']);            
        // }

        this.activatedRoute.params.subscribe(params => {
            // console.log(params)
            // const param = {
            //   id:  parseInt(params.id)
            // } as CardInfo;
            //this.info = param;
            if(params) {
                this.siteId = params['id']
                this.fetchSiteData();
            }
          })

    }

    async fetchSiteData() {
        // const dto$ = this.http.get(`${environment.apiUrl}/api/v1/workspaces/site/${this.siteId}/workspaces`);
        const site$ = this.http.get(`${environment.apiUrl}/api/v1/sites/${this.siteId}`);
        const site = await firstValueFrom(site$);
        
        // console.log(site)
        this.site = site;
        // this.dtoForm.patchValue(dto);
    }

    addWorkspace(template: String) {
        console.log(template);
    }

    gotoWorkspace(workspaceId: String) {
        console.log(workspaceId);
        this.router.navigate(["sites", this.siteId, 'workspaces', workspaceId]);
    }

    async updateWorkspace(id: string, name: string): Promise<boolean> {

        const dto$ = this.http.patch(`${environment.apiUrl}/api/v1/workspaces/${id}`, { name: name });
        const dto = await firstValueFrom(dto$);

        return dto ? true : false;
    }

    async onEditWorkspaceName(workspace: any) {
        Swal.fire({
            title: "Update!",
            text: "Enter new workspace name.",
            input: 'text',
            inputValue: workspace.name,
            showCancelButton: true        
        }).then(async (result) => {
            if (result.value) {
                //console.log("Result: " + result.value);
                if (await this.updateWorkspace(workspace.id, result.value)) {
                    workspace.name = result.value;
                    // Swal.fire({
                    //     title: "Update successfully",
                    //     text: "Your workspace name has been updated.",
                    //     icon: "success"
                    // }).then(()=> {
                    //     this.workspace.name = result.value;
                    // });

                    this.snackBar.open("Update workspace name successful", "OK", {
                        duration: 3000,
                        panelClass: ['notification-error'],
                       });
                }
            }
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action);
    }
}
