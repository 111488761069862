import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { HttpClient } from '@angular/common/http';
import { UserToken } from '../models/user-token';
import { environment } from '../../../environments/environment';
// import * as CryptoJS from 'crypto-js';
import { Constant } from '../../constant';

@Component({
    selector: 'app-sign-in',
    standalone: true,
    imports: [
        RouterLink,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        NgIf,
    ],
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss',
})
export class SignInComponent {
    // isToggled
    isToggled = false;

    http = inject(HttpClient);
    router = inject(Router);

    constructor(
        private fb: FormBuilder,
        public themeService: CustomizerSettingsService
    ) {
        this.authForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: [
                '',
                [Validators.required, Validators.minLength(8)],
            ],
        });
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }

    // encriptData(data: any) {
    //     return CryptoJS.AES.encrypt(data, Constant.EN_KEY).toString();
    // }

    // Password Hide
    hide = true;

    // Form
    authForm: FormGroup;
    onSubmit() {
        if (this.authForm.valid) {
            // debugger;

            // console.log('form valid');

            this.http
                .post<UserToken>(
                    `${environment.apiUrl}/api/v1/auth/login`,
                    this.authForm.value
                )
                .subscribe((res: any) => {
                    if (res) {
                        // console.log(res);
                        const userToken = res;
                        // alert('Login Success');

                        // const encriptedUser = this.encriptData(userToken.user);
                        // localStorage.setItem('user', encriptedUser);

                        localStorage.setItem('user', userToken.user);
                        localStorage.setItem('token', userToken.token);

                        this.router.navigateByUrl(
                            '/sites/1ee15589-3f80-4a16-85ff-9fdadc332674'
                        );
                    } else {
                        alert(res.message);
                    }
                });

            // this.authService
            //     .login(this.authForm.value)
            //     .pipe(first())
            //     .subscribe(
            //         (data) => {
            //             console.log(data);
            //             // const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
            //             this.router.navigate(['/label-management-page/labels']);
            //         },
            //         (error) => {
            //             console.log(error);
            //         }
            //     );

            // .subscribe(
            //     () => {
            //         // Set the redirect url.
            //         // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
            //         // to the correct page after a successful sign in. This way, that url can be set via
            //         // routing file and we don't have to touch here.
            //         //--const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
            //         // Navigate to the redirect url
            //         //--this._router.navigateByUrl(redirectURL);
            //         // this._router.navigate(['/dashboard']);
            //     },
            //     (response) => {
            //         console.log(response);
            //     }
            // );
            // this.router.navigate(['/label-management-page/labels']);
        } else {
            console.log('Form is invalid. Please check the fields.');
        }
    }
}
