// openaiApi.ts
import axios, { AxiosResponse } from "axios";

const API_KEY = "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";
const API_URL = "https://api.openai.com/v1/chat/completions"; // ใช้ URL chat/completions สำหรับโมเดลใหม่

export interface Message {
  role: "user" | "assistant" | "system";
  content: string;
}

export interface RequestData {
  model: string;
  messages: Message[];
  max_tokens: number;
}

export interface ResponseData {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: Array<{
    message: Message;
    finish_reason: string;
    index: number;
  }>;
}

export async function callOpenAiApi(data: RequestData): Promise<ResponseData> {
  try {
    const response: AxiosResponse<ResponseData> = await axios.post(API_URL, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
    });

    return response.data;
  } catch (error) {
    debugger;
    throw new Error(`API call failed: ${error}`);
  }
}