<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        <span class="material-symbols-outlined cursor-pointer" title="Back" routerLink="/topic-management-page">
            arrow_back_ios_new
        </span>&nbsp;Manage Topic
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Super Admin
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Management
        </li>
        <li class="breadcrumb-item position-relative cursor-pointer" routerLink="/topic-management-page">
            Topics
        </li>
        <li class="breadcrumb-item position-relative">
            Manage Topic
        </li>
    </ol>
</div>

<mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <form [formGroup]="dtoForm">
            <div class="row">
                <div class="col-lg-8">
                    <h5 class="title mt-0">
                        Topic Information
                    </h5>
                    <div class="row">

                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Topic Name
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Enter topic name
                                    </mat-label>
                                    <input matInput [formControlName]="'name'" required>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Label Class
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select class
                                    </mat-label>
                                    <mat-select [(value)]="labelClassSelected">
                                        <mat-option value="option1">
                                            ABC
                                        </mat-option>
                                        <mat-option value="option2">
                                            Workspace
                                        </mat-option>
                                        <mat-option value="option3">
                                            DT Card
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div> -->

                        <!-- <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Topic Type
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select topic type
                                    </mat-label>
                                    <mat-select [formControlName]="'topicType'">
                                        <mat-option value="header">
                                            Header
                                        </mat-option>
                                        <mat-option value="body">
                                            Body
                                        </mat-option>
                                        <mat-option value="dtcard">
                                            DT Card
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div> -->

                        <!-- <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Content Type
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select content type
                                    </mat-label>
                                    <mat-select [formControlName]="'contentType'">
                                        <mat-option value="text">
                                            Text Field
                                        </mat-option>
                                        <mat-option value="multipleText">
                                            Multiple Text Fields
                                        </mat-option>
                                        <mat-option value="date">
                                            Date
                                        </mat-option>
                                        <mat-option value="number">
                                            Number
                                        </mat-option>
                                        <mat-option value="progress">
                                            % Progress bar
                                        </mat-option>
                                        <mat-option value="textarea">
                                            Textarea
                                        </mat-option>
                                        <mat-option value="grid">
                                            Grid
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div> -->

                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Description
                                </label>
                                <mat-form-field class="textarea">
                                    <mat-label>
                                        Write your description
                                    </mat-label>
                                    <textarea [formControlName]="'description'" matInput placeholder="..."></textarea>
                                </mat-form-field>
                                <!-- <div class="NgxEditor__Wrapper">
                                    <ng-container *ngIf="editor">
                                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                        <ngx-editor [editor]="editor"></ngx-editor>
                                    </ng-container>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-4">
                    <h5 class="title mt-0">
                        Label Policy
                    </h5>

                    <div class="col-sm-6 col-lg-12">
                        <div class="mb-25">
                            <mat-checkbox [formControlName]="'isDTCard'" checked>
                                Is DT Card
                            </mat-checkbox>
                            <mat-checkbox [formControlName]="'isNullable'">
                                Is Nullable
                            </mat-checkbox>

                        </div>
                    </div>


                </div> -->
                <!-- <div class="row">

                    <div class="col-sm-4">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Status
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Select status
                                </mat-label>
                                <mat-select [(value)]="statusSelected">
                                    <mat-option value="option1">
                                        Active
                                    </mat-option>
                                    <mat-option value="option2">
                                        Deactive
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->


            </div>
        </form>
    </mat-card-content>
</mat-card>

<mat-card class="daxa-card edit-user-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="btn-box">
            <button mat-button (click)="save()">
                Save Changes
            </button>
        </div>
    </mat-card-content>
</mat-card>