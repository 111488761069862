import { Component, inject, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgxEditorModule, Editor, Toolbar, Validators } from 'ngx-editor';
import { UserDto } from '../dto/user-dto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-manage-user',
    standalone: true,
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxEditorModule,
        NgIf,
    ],
    templateUrl: './manage-user.component.html',
    styleUrl: './manage-user.component.scss',
})
export class ManageUserComponent {
    // Text Editor
    editor!: Editor | null; // Make it nullable
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    dto: UserDto;
    dtoForm: FormGroup;
    formMode: 'add' | 'update';
    id!: string | null;

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // Initialize the editor only in the browser
            this.editor = new Editor();
        }

        this.id  = this.activatedRoute.snapshot.paramMap.get('id');

        this.formMode = this.id ? 'update' : 'add';        

        this.dtoForm = this.formBuilder.group({
            // id: [''],
            name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            role: ['', [Validators.required]],
            // isActive: [true]
        });

        if(this.id) {
            this.fetchData();
        } else {
            this.dtoForm.patchValue({ isDTCard: true})
        }
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId) && this.editor) {
            this.editor.destroy();
        }
    }

    http = inject(HttpClient);
    
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private router: Router
    ) {}

    // File Uploader
    public multiple: boolean = false;

    // Select Value
    statusSelected = 'option1';
    roleSelected = 'option1';

    async fetchData() {
        const dto$ = this.http.get(`${environment.apiUrl}/api/v1/users/${this.id}`);
        const dto = await firstValueFrom(dto$);
        
        console.log(dto)
        this.dtoForm.patchValue(dto);
    }

    async update(): Promise<boolean> {
        const dtoValue: UserDto = this.dtoForm.getRawValue();

        const dto$ = this.http.patch(`${environment.apiUrl}/api/v1/users/${this.id}`, dtoValue);
        const dto = await firstValueFrom(dto$);

        return dto ? true : false;
    }

    async create(): Promise<boolean> {
        const dtoValue: UserDto = this.dtoForm.getRawValue();

        const dto$ = this.http.post(`${environment.apiUrl}/api/v1/users`, dtoValue);

        const dto = await firstValueFrom(dto$)
        
        return dto ? true : false;
    }

    async save() {
        // console.log(this.dtoForm.valid)

        if(this.dtoForm.valid) {
            if(this.formMode == 'add') {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // const dtoForSave = this.dtoForm.getRawValue();
                        // console.log(dtoForSave)
                        if (await this.create()) {
                            Swal.fire({
                                title: "Saved successfully",
                                text: "Your data has been saved.",
                                icon: "success"
                            }).then(async(result) => {
                                if(result) {
                                    this.router.navigate(["topic-management-page/topics"]);
                                }
                            });
                        }
                    }
                });
            } else {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const dtoForSave = this.dtoForm.getRawValue();
                        // console.log(dtoForSave)
                        if (await this.update()) {
                            Swal.fire({
                                title: "Update successfully",
                                text: "Your data has been updated.",
                                icon: "success"
                            });
                        }
                    }
                });
            }
        }
    }
}
