<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">

    <h5 class="mb-0">
        DT Card
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Apps
            </a>
        </li>
        <li class="breadcrumb-item position-relative" routerLink="/site-workspace/sites" style="cursor: pointer;">
            Site
        </li>
        <li class="breadcrumb-item position-relative" routerLink="/site-workspace/workspace" style="cursor: pointer;">
            Workspace
        </li>
        <li class="breadcrumb-item position-relative">
            DT Card
        </li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-12 col-md-12">
        <mat-card class="daxa-card chat-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-content>
                <div class="chat-header d-md-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">

                        <div class="title">
                            <span class="d-block fw-medium">
                                (1,3) Experience : กระบวนการผลิต นศ. ที่สามารถทำงานได้ทั่วโลก
                            </span>
                        </div>
                    </div>
                    <div class="buttons-list d-flex align-items-center">
                        <!-- <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                call
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                videocam
                            </i>
                        </button> -->
                        <button type="button" class="text-body" mat-button [matMenuTriggerFor]="chatHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button>
                        <mat-menu #chatHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <!-- <button mat-menu-item>
                                Mute Chat
                            </button>
                            <button mat-menu-item>
                                Delete
                            </button>
                            <button mat-menu-item>
                                Block
                            </button> -->
                        </mat-menu>
                    </div>
                </div>
                <div class="border"><br /></div>
                <div class="row">
                    <div class="col-sm-6 col-xxl-3">
                        <!-- <div class="col-sm-12 col-xxl-4"> -->

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Owner name
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field>
                                    <!-- <mat-label>
                                        Enter owner name
                                    </mat-label> -->
                                    <input matInput value="Chalermpol Chueayen">
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>

                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Objective
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>

                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Start date
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field>
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="pickerStartDate">
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matIconSuffix
                                        [for]="pickerStartDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerStartDate></mat-datepicker>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>

                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        End date
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field>
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="pickerEndDate">
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matIconSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerEndDate></mat-datepicker>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>

                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        งบประมาณการ
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field>
                                    <input matInput value="">
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>

                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        งบที่ได้จริง
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field>
                                    <input matInput value="">
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>

                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        %Completed
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Notes/Chat
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        ผู้ที่เกี่ยวข้อง/ผู้มีส่วนได้ส่วนเสีย
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>







                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Key process
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Key output
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Visual Notes/Chat
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Skills
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Application Suite
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        Data
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>


                    <div class="col-sm-6 col-xxl-3">

                        <mat-card class="daxa-card mb-25 border-radius border-none d-block"
                            [class.component-dark-theme]="themeService.isDark()">
                            <mat-card-header>
                                <mat-card-title>
                                    <h5 class="mb-0">
                                        กฏหมาย/กฏระเบียบ
                                    </h5>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col">
                                        <mat-card class="daxa-card mb-25 border-radius border-none bg-white d-block"
                                            [class.component-dark-theme]="themeService.isDark()">
                                            <mat-card-content>
                                                <p></p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>