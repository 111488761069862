<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Users
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Super Admin
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Management
        </li>
        <li class="breadcrumb-item position-relative">
            Users
        </li>
    </ol>
</div>

<!-- Users -->
<mat-card class="daxa-card users-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()">
    <mat-card-header>
        <mat-card-title>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search user..."
                    (keyup)="applyFilter($event)" #input>
            </form>
        </mat-card-title>
        <mat-card-subtitle>
            <a mat-button class="add-new-btn" routerLink="/user-management-page/manage">
                + Add New User
            </a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="users-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- User ID Column -->
                    <!-- <ng-container matColumnDef="userID">
                        <th mat-header-cell *matHeaderCellDef>
                            User ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.userID}}
                        </td>
                    </ng-container> -->

                    <!-- User Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            User
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.name}}
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email}}
                        </td>
                    </ng-container>

                    <!-- Location Column -->
                    <!-- <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef>
                            Location
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.location}}
                        </td>
                    </ng-container> -->

                    <!-- Phone Column -->
                    <!-- <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef>
                            Phone
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.phone}}
                        </td>
                    </ng-container> -->

                    <!-- Projects Column -->
                    <!-- <ng-container matColumnDef="projects">
                        <th mat-header-cell *matHeaderCellDef>
                            Projects
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.projects}}
                        </td>
                    </ng-container> -->

                    <!-- Join Date Column -->
                    <!-- <ng-container matColumnDef="joinDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Join Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.joinDate}}
                        </td>
                    </ng-container> -->

                    <!-- Role Column -->
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>
                            Role
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.role}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <!-- <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge active d-inline-block" *ngIf="element.status.active">
                                {{element.status.active}}
                            </span>
                            <span class="daxa-badge deactive d-inline-block" *ngIf="element.status.deactive">
                                {{element.status.deactive}}
                            </span>
                        </td>
                    </ng-container> -->

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" routerLink="/user-management-page/{{element.id}}" mat-button
                                    matTooltip="View" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                       edit
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                </table>
            </div>
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>