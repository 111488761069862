import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-template-management-page',
    standalone: true,
    imports: [RouterLink, RouterOutlet],
    templateUrl: './template-management-page.component.html',
    styleUrl: './template-management-page.component.scss',
})
export class TemplateManagementPageComponent {}
