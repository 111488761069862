<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Editors
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Forms
        </li>
        <li class="breadcrumb-item position-relative">
            Editors
        </li>
    </ol>
</div>

<!-- Editors -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Ngx Editor
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="NgxEditor__Wrapper">
            <ng-container *ngIf="editor">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                <ngx-editor [editor]="editor"></ngx-editor>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>