import { NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import {
    MatPaginator,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { Constant } from '../../../constant';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2'

export interface TopicData {
    id: string;
    name: string;
    topicType: string;
    description: string;
    action: any;
}

@Component({
    selector: 'app-list-topic',
    standalone: true,
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        MatTableModule,
        MatPaginatorModule,
        NgIf,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    templateUrl: './list-topic.component.html',
    styleUrl: './list-topic.component.scss',
})
export class ListTopicComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = [
        'name',
        'description',
        'action',
    ];
    dataSource = new MatTableDataSource<TopicData>([]); //(ELEMENT_DATA);
    selection = new SelectionModel<TopicData>(true, []);
    @ViewChild(MatPaginator) paginator: MatPaginator;

    length = 1000;
    pageSize = 20;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: TopicData): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1
            }`;
    }

    // Search Filter
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    // isToggled
    isToggled = false;

    http = inject(HttpClient);
    topicList: TopicData[] = [];

    constructor(public themeService: CustomizerSettingsService) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.paginator.page.subscribe((event) => {
            // console.log(event)
        });
    }

    ngOnInit(): void {
        this.getAll();
    }

    // decriptData(data: string) {
    //     const decriptVal = CryptoJS.AES.decrypt(data, Constant.EN_KEY);
    //     return decriptVal.toString(CryptoJS.enc.Utf8);
    // }

    getAll() {
        // debugger;
        this.http
            .get(`${environment.apiUrl}/api/v1/topics`)
            .subscribe((res: any) => {
                this.topicList = res;
                // console.log(this.topicList);
                this.dataSource.data = this.topicList;
            });
    }

    delete(id: string) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.http
                    .delete(`${environment.apiUrl}/api/v1/topics/${id}`)
                    .subscribe((res: any) => {
                        this.getAll()
                        // console.log(res)
                        Swal.fire({
                            title: "Deleted successfully",
                            text: "Your data has been removed.",
                            icon: "success"
                        });
                    });
            }
        });
    }
}