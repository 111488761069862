import { NgIf } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { HttpClient } from '@angular/common/http';
import { UserDto } from '../dto/user-dto';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-list-user',
    standalone: true,
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        MatTableModule,
        MatPaginatorModule,
        NgIf,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    templateUrl: './list-user.component.html',
    styleUrl: './list-user.component.scss',
})
export class ListUserComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = [
        'name',
        'email',
        'role',
        'action',
    ];
    dataSource = new MatTableDataSource<UserDto>([]);
    selection = new SelectionModel<UserDto>(true, []);

    length = 1000;
    pageSize = 20;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    // Search Filter
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    // isToggled
    isToggled = false;

    http = inject(HttpClient);

    userList: UserDto[] = [];

    constructor(public themeService: CustomizerSettingsService) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }
    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        // debugger;
        this.http
            .get(`${environment.apiUrl}/api/v1/users`)
            .subscribe((res: any) => {
                this.userList = res;
                console.log(this.userList);
                this.dataSource.data = this.userList;
            });
    }
}

// const ELEMENT_DATA: PeriodicElement[] = [
//     {
//         userID: '#ARP-1217',
//         user: {
//             img: 'images/users/user15.jpg',
//             name: 'Chalermpol Chueayen',
//         },
//         email: 'chalermpol@mail.com',
//         role: 'Super Admin',
//         // projectAccess: 'Hotel management system, Python upgrade',
//         status: {
//             active: 'Active',
//             // deactive: 'Deactive',
//         },
//         action: {
//             view: 'visibility',
//             edit: 'edit',
//             delete: 'delete',
//         },
//     },
//     {
//         userID: '#ARP-1364',
//         user: {
//             img: 'images/users/user7.jpg',
//             name: 'Nonglak Nakphu',
//         },
//         email: 'nonglak@mail.com',
//         role: 'Site Admin',
//         // projectAccess: 'Project monitoring, Project alpho ',
//         status: {
//             active: 'Active',
//             // deactive: 'Deactive',
//         },
//         action: {
//             view: 'visibility',
//             edit: 'edit',
//             delete: 'delete',
//         },
//     },
// ];