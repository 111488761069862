import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-workspace-card-content',
    standalone: true,
    imports: [MatCardModule],
    templateUrl: './workspace-card-content.component.html',
    styleUrl: './workspace-card-content.component.scss',
})
export class WorkspaceCardContentComponent {
    @Input() valueText = '';
}
