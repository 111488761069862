<!-- Breadcrumb -->
<div class="breadcrumb-card mb-20 d-md-flex align-items-center justify-content-between">

    <h5 class="mb-0">
        <span class="material-symbols-outlined cursor-pointer" style="vertical-align: middle; font-size: large;"
            title="Back" routerLink="/sites/{{params.siteId}}">
            arrow_back_ios_new
        </span>&nbsp;Workspaces
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Apps
            </a>
        </li>
        <li class="breadcrumb-item position-relative" routerLink="/sites/{{params.siteId}}" style="cursor: pointer;">
            Site
        </li>
        <li class="breadcrumb-item position-relative">
            Workspace
        </li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-12 col-md-12">
        <mat-card class="daxa-card chat-card mb-20 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-content>
                <div class="chat-header d-md-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <div class="img position-relative">
                            <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                            <span class="active-status"></span>
                        </div>
                        <div class="title">
                            <!-- <span class="d-block fw-medium"> -->
                            <span class="d-block" style="font-size: larger; font-weight: 300;">
                                {{workspace?.name}}
                                <i class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                    (click)="onEditWorkspaceName()" *ngIf="workspace?.name">
                                    edit
                                </i>
                            </span>
                            <!-- <span class="d-block text-success">
                                20 members
                            </span> -->
                        </div>
                    </div>
                    <div class="buttons-list d-flex align-items-center">
                        <!-- <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                call
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                videocam
                            </i>
                        </button> -->
                        <button mat-button class="fw-medium" (click)="doGenAi('Gen AI - All')"
                            *ngIf="workspace?.workspaceLabels.length > 0">
                            <img src="assets/ChatGPT-Logo.png" width="30px" style="vertical-align: middle;" />Gen AI -
                            All
                        </button>
                        <!-- <button type="button" class="text-body" mat-button [matMenuTriggerFor]="chatHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button> -->
                        <mat-menu #chatHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item (click)="doGenAi('Gen AI - All')">
                                <img src="assets/ChatGPT-Logo.png" width="30px" style="vertical-align: middle;" />Gen
                                All
                            </button>
                            <button mat-menu-item (click)="doGenAi('Gen AI -  Top to Bottom')">
                                <img src="assets/ChatGPT-Logo.png" width="30px" style="vertical-align: middle;" />Gen
                                Top to Bottom
                            </button>
                            <button mat-menu-item (click)="doGenAi('Gen AI -  Bottom to Top')">
                                <img src="assets/ChatGPT-Logo.png" width="30px" style="vertical-align: middle;" />Gen
                                Bottom to Top
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="border"></div>

            </mat-card-content>
            <!-- <mat-card-actions>
                <button type="button" class="text-body" mat-button [matMenuTriggerFor]="chatHeaderMenu">
                    <i class="material-symbols-outlined">
                        more_horiz
                    </i>
                </button>
            </mat-card-actions> -->
        </mat-card>
    </div>
</div>
<div class="row">

    <div class="col-lg-12 col-md-12">
        <mat-card class="daxa-card chat-card mb-20 border-radius bg-white border-none d-block">

            <mat-card-header>
                <div style="text-align:center;padding: 0px">
                    <mat-card-title class="text-oneline" > <!-- [style.width.px]="width - 50" -->
                        <h5 class="mb-0" style="font-weight: 300;">
                            <!-- (A) Vision -->
                            {{aData?.labelName}}
                            <!-- <app-editable-textbox [value]="aData?.labelName"></app-editable-textbox> -->

                            <i class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="onEditLabelName('a',aData)" *ngIf="aData?.labelName">
                                edit
                            </i>
                        </h5>

                        <!-- <mat-form-field>
                            <mat-label></mat-label>  placeholder="{{aData?.labelName}}"
                            <input matInput (keyup.enter)="addDTCard(aData, $event)">
                        </mat-form-field> -->
                    </mat-card-title>
                </div>
                <!-- <mat-card-subtitle>
                    <span class="material-symbols-outlined cursor-pointer text-body" (click)="collapsedA=!collapsedA">
                        {{collapsedA == false ? 'collapse' : 'expand'}}_content
                    </span>
                </mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content *ngIf="!collapsedA">

                <!-- <div class="example-boundary">
                    <div class="example-box bg-white border-radius d-flex align-items-center text-center position-relative justify-content-center"
                        cdkDragBoundary=".example-boundary" cdkDrag>
                        <app-workspace-card-content [valueText]="'เป็นร้านกาแฟอันดับ 1 ของจังหวัดเพชรบูรณ์'" />
                    </div>
                </div>  (click)="removeDTCard(aData, child.id)"-->

                <mat-chip-set cdkDropList cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event, aData?.children)">

                    @for (child of aData?.children; track child) {
                    <mat-chip class="custom-chip" cdkDrag>
                        <mat-icon matChipRemove [matMenuTriggerFor]="labelMenu">more_vert</mat-icon>
                        <mat-menu #labelMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item (click)="removeDTCard(aData, child.id)">
                                <i class="material-symbols-outlined"
                                    style="font-size: medium; cursor: pointer;vertical-align: middle;">
                                    delete
                                </i>
                                Remove
                            </button>
                            <!-- <button mat-menu-item>
                                Create DT Card
                            </button> -->
                        </mat-menu>
                        <span style="font-weight: 300;">{{child.labelName}}</span>
                        <!-- <i class="material-symbols-outlined dt-card-icon">
                            more_vert
                        </i> -->
                    </mat-chip>
                    }
                </mat-chip-set>

            </mat-card-content>
            <mat-card-footer>
                <mat-form-field>
                    <!-- <mat-label></mat-label>  placeholder="{{aData?.labelName}}"-->
                    <input matInput (keyup.enter)="addDTCard(aData, $event)">
                </mat-form-field>
            </mat-card-footer>
        </mat-card>
    </div>
</div>

<div class="row">

    <div class="col-lg-12 col-md-12">
        <mat-card class="daxa-card chat-card mb-20 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header>
                <div style="text-align:center;padding: 0px">
                    <mat-card-title>
                        <h5 class="mb-0" style="font-weight: 300">
                            <!-- (B) Mission -->
                            {{bData?.labelName}}
                            <i class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="onEditLabelName('b',bData)" *ngIf="bData?.labelName">
                                edit
                            </i>
                        </h5>
                    </mat-card-title>
                </div>
                <!-- <mat-card-subtitle>
                    <span class="material-symbols-outlined cursor-pointer text-body" (click)="collapsedB=!collapsedB">
                        {{collapsedB == false ? 'collapse' : 'expand'}}_content
                    </span>
                </mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content *ngIf="!collapsedB">

                <mat-chip-set cdkDropList cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event,bData?.children)">
                    @for (child of bData?.children; track child) {
                    <mat-chip cdkDrag>
                        <mat-icon matChipRemove [matMenuTriggerFor]="labelMenu">more_vert</mat-icon>
                        <mat-menu #labelMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item (click)="removeDTCard(bData, child.id)">
                                <i class="material-symbols-outlined"
                                    style="font-size: medium; cursor: pointer;vertical-align: middle;">
                                    delete
                                </i>
                                Remove
                            </button>
                            <!-- <button mat-menu-item>
                                Create DT Card
                            </button> -->
                        </mat-menu>
                        <span style="font-weight: 300;">{{child.labelName}}</span>
                    </mat-chip>
                    }
                </mat-chip-set>

                <!-- <div class="example-boundary">
                
                    <div class="example-box bg-white border-radius d-flex align-items-center text-center position-relative justify-content-center"
                        cdkDragBoundary=".example-boundary" cdkDrag>
                        <app-workspace-card-content [valueText]="'ขายกาแฟให้มีรายได้ 500,000 บาท'" />
                    </div>
                    <div class="example-box bg-white border-radius d-flex align-items-center text-center position-relative justify-content-center"
                        cdkDragBoundary=".example-boundary" cdkDrag>
                        <app-workspace-card-content [valueText]="'มีลูกค้าเข้าร้านไม่น้อยกว่า 1,000 คน'" />
                    </div>

                </div> -->

            </mat-card-content>
            <mat-card-footer>
                <mat-form-field>
                    <!-- <mat-label>+ Add DT Card</mat-label> -->
                    <input matInput (keyup.enter)="addDTCard(bData, $event)">
                </mat-form-field>
            </mat-card-footer>
        </mat-card>
    </div>

</div>

<div class="row">

    <div class="col-lg-12 col-md-12">
        <mat-card class="daxa-card chat-card mb-20 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header>
                <div style="text-align:center;padding: 0px">
                    <mat-card-title>
                        <h5 class="mb-0 dt-card-header" style="font-weight: 300;">
                            <!-- (C) เงื่อนไขในการดำเนินธุรกิจ -->
                            {{cData?.labelName}}
                            <i class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                (click)="onEditLabelName('c',cData)" *ngIf="cData?.labelName">
                                edit
                            </i>
                        </h5>
                    </mat-card-title>
                </div>
                <!-- <mat-card-subtitle>
                    <span class="material-symbols-outlined cursor-pointer text-body" (click)="collapsedC=!collapsedC">
                        {{collapsedC == false ? 'collapse' : 'expand'}}_content
                    </span>
                </mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content *ngIf="!collapsedC">
                <mat-chip-set cdkDropList cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event, cData?.children)">
                    @for (child of cData?.children; track child) {
                    <mat-chip id="{{child.id}}" cdkDrag>
                        <mat-icon matChipRemove [matMenuTriggerFor]="labelMenu">more_vert</mat-icon>
                        <mat-menu #labelMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item (click)="removeDTCard(cData, child.id)">
                                <i class="material-symbols-outlined"
                                    style="font-size: medium; cursor: pointer;vertical-align: middle;">
                                    delete
                                </i>
                                Remove
                            </button>
                            <!-- <button mat-menu-item>
                                Create DT Card
                            </button> -->
                        </mat-menu>
                        <span style="font-weight: 300;">{{child.labelName}}</span>

                    </mat-chip>
                    }
                </mat-chip-set>

                <!-- <div class="example-boundary">

                    <div class="example-box bg-white border-radius d-flex align-items-center text-center position-relative justify-content-center"
                        cdkDragBoundary=".example-boundary" cdkDrag>
                        <app-workspace-card-content [valueText]="'ไม่ทิ้งของเสียหรือปล่อยน้ำสกปรกลงในที่สาธารณะ'" />
                    </div>
                    <div class="example-box bg-white border-radius d-flex align-items-center text-center position-relative justify-content-center"
                        cdkDragBoundary=".example-boundary" cdkDrag>
                        <app-workspace-card-content [valueText]="'ลดต้นทุนในการดำเนินงาน 10% ภายในสิ้นปี'" />
                    </div>
                </div> -->
            </mat-card-content>
            <mat-card-footer>
                <mat-form-field>
                    <!-- <mat-label>+ Add DT Card</mat-label> -->
                    <input matInput (keyup.enter)="addDTCard(cData, $event)">
                </mat-form-field>
            </mat-card-footer>
        </mat-card>
    </div>

</div>

<div class="row">
    <div class="col-lg-12 col-md-12">
        @for(row of labelMetrixs; track row) {
        <div class="row">

            @for(value of row; track value) {
            <div class="col-lg-4 col-md-4">
                <mat-card class="daxa-card mb-20 border-radius bg-white border-none d-block">
                    <mat-card-header class="custom-header">
                        <mat-card-title>
                            <h5 class="mb-0" style="font-weight: 300;">
                                {{value.labelName}}
                                <i class="material-symbols-outlined" style="font-size: medium; cursor: pointer;"
                                    (click)="onEditLabelName('d',value)">
                                    edit
                                </i>
                            </h5>
                        </mat-card-title>
                        <!-- <mat-card-subtitle>
                        <span class="material-symbols-outlined cursor-pointer text-body" (click)="collapsedLabelCard=!collapsedLabelCard">
                            {{collapsedLabelCard == false ? 'collapse' : 'expand'}}_content
                        </span>
                    </mat-card-subtitle> -->
                    </mat-card-header>
                    <mat-card-content *ngIf="!collapsedLabelCard">
                        <mat-chip-set cdkDropList cdkDropListOrientation="horizontal"
                            (cdkDropListDropped)="drop($event, value?.children)">
                            @for (child of value?.children; track child) {

                            <mat-chip class="body-mat-chip" cdkDrag>
                                <mat-icon matChipRemove [matMenuTriggerFor]="labelMenu">more_vert</mat-icon>
                                <mat-menu #labelMenu="matMenu" class="card-header-menu" xPosition="before">
                                    <button mat-menu-item (click)="removeDTCard(value, child.id)">
                                        <i class="material-symbols-outlined"
                                            style="font-size: medium; cursor: pointer;vertical-align: middle;">
                                            delete
                                        </i>
                                        Remove
                                    </button>
                                    <!-- <button mat-menu-item>
                                                                    Create DT Card
                                                                </button> -->
                                </mat-menu>
                                <span style="font-weight: 300;white-space: wrap;">{{child.labelName}}</span>

                            </mat-chip>

                            }
                        </mat-chip-set>
                    </mat-card-content>
                    <!-- <mat-card-actions class="ps-15 mt-10 mb-10">
                    <button mat-flat-button class="gray mr-15">LIKE</button>
                    <button mat-flat-button class="gray">SHARE</button>
                </mat-card-actions> -->
                    <mat-card-footer>
                        <mat-form-field>
                            <!-- <mat-label>+...</mat-label> -->
                            <input matInput (keyup.enter)="addDTCard(value, $event)">
                        </mat-form-field>
                    </mat-card-footer>
                </mat-card>
            </div>
            }

        </div>
        }
    </div>
</div>


<!-- <div class="row">
    <div class="col-lg-12 col-md-12"> -->
<!-- <mat-card class="daxa-card dtcard-box mb-25 border-radius bg-white border-none d-block">

            <mat-card-content>
                <div class="row">
                    <mat-grid-list class="mat-grid-list-box" cols="3" rowHeight="500px">
                        <mat-grid-tile>
                            <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
                                <mat-card-header>
                                    <mat-card-title>
                                        <h5 class="mb-0">
                                            (1,3) Experience
                                        </h5>
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <div class="mb-25">
                                        <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                            <mat-card-content>
                                                <p>กระบวนการผลิตความคิดเชิงสร้างสรรค์และนวัตกรรม</p>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                            <mat-card-content>
                                                <p>กระบวนการผลิตความคิดเชิงสร้างสรรค์และนวัตกรรม</p>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                            <mat-card-content>
                                                <p>กระบวนการผลิตความคิดเชิงสร้างสรรค์และนวัตกรรม</p>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                            <mat-card-content>
                                                <p>กระบวนการผลิตความคิดเชิงสร้างสรรค์และนวัตกรรม</p>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile>2</mat-grid-tile>
                        <mat-grid-tile>3</mat-grid-tile>
                        <mat-grid-tile>4</mat-grid-tile>
                        <mat-grid-tile>5</mat-grid-tile>
                        <mat-grid-tile>6</mat-grid-tile>
                        <mat-grid-tile>7</mat-grid-tile>
                        <mat-grid-tile>8</mat-grid-tile>
                        <mat-grid-tile>9</mat-grid-tile>
                    </mat-grid-list>
                </div>
            </mat-card-content>
        </mat-card> -->
<!-- <div class="col-lg-4 col-md-6">
                    
                </div> -->
<!-- <div class="col-lg-4 col-md-6">
                    <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
                        <mat-card-header>
                            <mat-card-title>
                                <h5 class="mb-0">
                                    (2,3) Data
                                </h5>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>โครงสร้างองค์กร</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลบุคลากร</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลนักศึกษา</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลโครงการวิจัย</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลผลงานตีพิมพ์</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลการบริการวิชาการ</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลอาคารสถานที่</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>ข้อมูลวัสดุครุภัณฑ์</p>
                                </mat-card-content>
                            </mat-card>
                            <button mat-button class="add-another-task-btn">
                                + Add DT Card
                            </button>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
                        <mat-card-header>
                            <mat-card-title>
                                <h5 class="mb-0">
                                    (2,2) Laws, compliance & audit
                                </h5>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>กฎหมาย/กฎระเบียบ/ข้อบังคับ ที่เกี่ยวข้องกับการบริหารจัดการยานพาหนะ</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>กฎหมาย/กฎระเบียบ/ข้อบังคับ
                                        ที่เกี่ยวข้องกับการประกันคุณภาพการศึกษาและบริหารความเสี่ยง
                                    </p>
                                </mat-card-content>
                            </mat-card>
                            <button mat-button class="add-another-task-btn">
                                + Add DT Card
                            </button>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
                        <mat-card-header>
                            <mat-card-title>
                                <h5 class="mb-0">
                                    (2,1) Corporate admin. & back office
                                </h5>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>งานประกันคุณภาพการศึกษาและบริหารความเสี่ยง</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>งานแผน งบประมาณ อัตรากำลังและบริหารโครงการ</p>
                                </mat-card-content>
                            </mat-card>
                            <button mat-button class="add-another-task-btn">
                                + Add DT Card
                            </button>
                        </mat-card-content>
                    </mat-card>
                </div> -->

<!-- <div class="col-lg-4 col-md-6">
                    <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
                        <mat-card-header>
                            <mat-card-title>
                                <h5 class="mb-0">
                                    (3,3) Security technology
                                </h5>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>การตรวจจับและป้องกันการบุกรุก</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>การบริหารจัดการระบบความเชื่อถือได้</p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                                <mat-card-content>
                                    <p>การจัดการเครือข่ายสำรอง</p>
                                </mat-card-content>
                            </mat-card>
                         
                
          

        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        (3,2) Monitoring technology
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                    <mat-card-content>
                        <p>Strategic Goal Monitoring (แผงควบคุมเป้าหมายเชิงกลยุทธ์) </p>
                    </mat-card-content>
                </mat-card>
                <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                    <mat-card-content>
                        <p>KPIs Monitoring (แผงควบคุมตัวชี้วัดหลัก)</p>
                    </mat-card-content>
                </mat-card>
                <button mat-button class="add-another-task-btn">
                    + Add DT Card
                </button>
               
           </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        (3,1) Platform technology
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                    <mat-card-content>
                        <p>FON MIS ระบบสารสนเทศข้อมูลบุคลากรและบริการต่างๆ</p>
                    </mat-card-content>
                </mat-card>
                <mat-card class="daxa-card mb-25 border-radius bg-gray border-none d-block">
                    <mat-card-content>
                        <p>FON BM ระบบกำกับติดตามการดำเนินการตามงบประมาณและแผนยุทธศาสตร์</p>
                    </mat-card-content>
                </mat-card>
                <button mat-button class="add-another-task-btn">
                    + Add DT Card
                </button>
            </mat-card-content>
        </mat-card> -->
<!--   </div>
 </div> -->