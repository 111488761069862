import { Routes } from '@angular/router';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { ToDoListComponent } from './apps/to-do-list/to-do-list.component';
import { CalendarComponent } from './apps/calendar/calendar.component';
import { ContactsComponent } from './apps/contacts/contacts.component';
import { ChatComponent } from './apps/chat/chat.component';
import { KanbanBoardComponent } from './apps/kanban-board/kanban-board.component';
import { FileManagerComponent } from './apps/file-manager/file-manager.component';
import { MyDriveComponent } from './apps/file-manager/my-drive/my-drive.component';
import { AssetsComponent } from './apps/file-manager/assets/assets.component';
import { ProjectsComponent } from './apps/file-manager/projects/projects.component';
import { PersonalComponent } from './apps/file-manager/personal/personal.component';
import { ApplicationsComponent } from './apps/file-manager/applications/applications.component';
import { DocumentsComponent } from './apps/file-manager/documents/documents.component';
import { MediaComponent } from './apps/file-manager/media/media.component';
import { EmailComponent } from './apps/email/email.component';
import { InboxComponent } from './apps/email/inbox/inbox.component';
import { ComposeComponent } from './apps/email/compose/compose.component';
import { ReadComponent } from './apps/email/read/read.component';
import { FormsComponent } from './forms/forms.component';
import { BasicElementsComponent } from './forms/basic-elements/basic-elements.component';
import { AdvancedElementsComponent } from './forms/advanced-elements/advanced-elements.component';
import { WizardComponent } from './forms/wizard/wizard.component';
import { EditorsComponent } from './forms/editors/editors.component';
import { FileUploaderComponent } from './forms/file-uploader/file-uploader.component';
import { InternalErrorComponent } from './common/internal-error/internal-error.component';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { ComingSoonPageComponent } from './pages/coming-soon-page/coming-soon-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { TermsConditionsComponent } from './settings/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './settings/privacy-policy/privacy-policy.component';
import { ConnectionsComponent } from './settings/connections/connections.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { AccountSettingsComponent } from './settings/account-settings/account-settings.component';
import { SettingsComponent } from './settings/settings.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { ConfirmEmailComponent } from './authentication/confirm-email/confirm-email.component';
import { LockScreenComponent } from './authentication/lock-screen/lock-screen.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { MembersPageComponent } from './pages/members-page/members-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { StarterComponent } from './starter/starter.component';
import { EditAnEventComponent } from './pages/events-page/edit-an-event/edit-an-event.component';
import { CreateAnEventComponent } from './pages/events-page/create-an-event/create-an-event.component';
import { EventDetailsComponent } from './pages/events-page/event-details/event-details.component';
import { EventsListComponent } from './pages/events-page/events-list/events-list.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { TeamMembersComponent } from './pages/users-page/team-members/team-members.component';
import { CreateLabelComponent } from './pages/topic-management-page/create-label/create-label.component';
import { SiteManagementPageComponent } from './pages/site-management-page/site-management-page.component';
import { SiteWorkpsacesComponent } from './pages/site-workpsaces/site-workspaces.component';
import { WorkspaceComponent } from './pages/site-workpsaces/workspace/workspace.component';
import { SiteComponent } from './pages/site-workpsaces/site/site.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';
import { AddUserComponent } from './pages/user-management-page/add-user/add-user.component';
import { ManageUserComponent } from './pages/user-management-page/manage/manage-user.component';
import { DtCardDetailsComponent } from './pages/site-workpsaces/dt-card-details/dt-card-details.component';
import { TopicManagementPageComponent } from './pages/topic-management-page/topic-management-page.component';
import { ListTopicComponent } from './pages/topic-management-page/list/list-topic.component';
import { ManageTopicComponent } from './pages/topic-management-page/manage/manage-topic.component';
import { ListUserComponent } from './pages/user-management-page/list/list-user.component';
import { ListSiteComponent } from './pages/site-management-page/list/list-site.component';
import { ManageSiteComponent } from './pages/site-management-page/manage/manage-site.component';
import { ListTemplateComponent } from './pages/template-management-page/list/list-template.component';
import { ManageTempateComponent } from './pages/template-management-page/manage/manage-template.component';
import { TemplateManagementPageComponent } from './pages/template-management-page/template-management-page.component';

export const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'authentication' },
    {
        path: 'authentication',
        component: AuthenticationComponent,
        children: [
            { path: '', component: SignInComponent },
            { path: 'sign-up', component: SignUpComponent },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            { path: 'reset-password', component: ResetPasswordComponent },
            { path: 'lock-screen', component: LockScreenComponent },
            { path: 'confirm-email', component: ConfirmEmailComponent },
            { path: 'logout', component: LogoutComponent },
        ],
    },
    {
        path: 'user-management-page',
        component: UserManagementPageComponent,
        children: [
            { path: '', component: ListUserComponent },
            { path: ':id', component: ManageUserComponent },
        ],
    },
    {
        path: 'topic-management-page',
        component: TopicManagementPageComponent,
        children: [
            { path: '', component: ListTopicComponent },
            { path: ':id', component: ManageTopicComponent }
        ],
    },
    {
        path: 'template-management-page',
        component: TemplateManagementPageComponent,
        children: [
            { path: '', component: ListTemplateComponent },
            { path: ':id', component: ManageTempateComponent },
        ],
    },
    {
        path: 'site-management-page',
        component: SiteManagementPageComponent,
        children: [
            { path: '', component: ListSiteComponent },
            { path: ':id', component: ManageSiteComponent },
        ],
    },
    {
        // path: 'site-workspace',
        path: 'sites',
        component: SiteWorkpsacesComponent,
        children: [
            { path: '', component: SiteComponent },
            { path: ':id', component: SiteComponent },
            { path: ':siteId/workspaces/:workspaceId', component: WorkspaceComponent },
            { path: 'dt-card', component: DtCardDetailsComponent },
        ],
    },
    { path: 'to-do-list', component: ToDoListComponent },
    { path: 'calendar', component: CalendarComponent },
    { path: 'contacts', component: ContactsComponent },
    { path: 'chat', component: ChatComponent },
    { path: 'kanban-board', component: KanbanBoardComponent },
    {
        path: 'file-manager',
        component: FileManagerComponent,
        children: [
            { path: '', component: MyDriveComponent },
            { path: 'assets', component: AssetsComponent },
            { path: 'projects', component: ProjectsComponent },
            { path: 'personal', component: PersonalComponent },
            { path: 'applications', component: ApplicationsComponent },
            { path: 'documents', component: DocumentsComponent },
            { path: 'media', component: MediaComponent },
        ],
    },
    {
        path: 'email',
        component: EmailComponent,
        children: [
            { path: '', component: InboxComponent },
            { path: 'compose', component: ComposeComponent },
            { path: 'read', component: ReadComponent },
        ],
    },  
    {
        path: 'events',
        component: EventsPageComponent,
        children: [
            { path: '', component: EventsListComponent },
            { path: 'event-details', component: EventDetailsComponent },
            { path: 'create-an-event', component: CreateAnEventComponent },
            { path: 'edit-an-event', component: EditAnEventComponent },
        ],
    },
    { path: 'starter', component: StarterComponent },
    { path: 'notifications', component: NotificationsPageComponent },
    { path: 'members', component: MembersPageComponent },
    {
        path: 'users',
        component: UsersPageComponent,
        children: [
            { path: '', component: TeamMembersComponent },
            { path: 'users-list', component: ListUserComponent },
            { path: 'add-user', component: AddUserComponent },
        ],
    },
    // {
    //     path: 'authentication',
    //     component: AuthenticationComponent,
    //     children: [
    //         {path: '', component: SignInComponent},
    //         {path: 'sign-up', component: SignUpComponent},
    //         {path: 'forgot-password', component: ForgotPasswordComponent},
    //         {path: 'reset-password', component: ResetPasswordComponent},
    //         {path: 'lock-screen', component: LockScreenComponent},
    //         {path: 'confirm-email', component: ConfirmEmailComponent},
    //         {path: 'logout', component: LogoutComponent}
    //     ]
    // },
    {
        path: 'settings',
        component: SettingsComponent,
        children: [
            { path: '', component: AccountSettingsComponent },
            { path: 'change-password', component: ChangePasswordComponent },
            { path: 'connections', component: ConnectionsComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'terms-conditions', component: TermsConditionsComponent },
        ],
    },
    { path: 'search', component: SearchPageComponent },
    { path: 'coming-soon', component: ComingSoonPageComponent },
    { path: 'blank-page', component: BlankPageComponent },
    { path: 'internal-error', component: InternalErrorComponent },
    {
        path: 'forms',
        component: FormsComponent,
        children: [
            { path: '', component: BasicElementsComponent },
            { path: 'advanced-elements', component: AdvancedElementsComponent },
            { path: 'wizard', component: WizardComponent },
            { path: 'editors', component: EditorsComponent },
            { path: 'file-uploader', component: FileUploaderComponent },
        ],
    },
    // Here add new pages component

    { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];
