import { Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
// import { Template3x3Component } from '../template3x3/template3x3.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { isPlatformBrowser, NgIf, AsyncPipe } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, FormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TemplateDto } from '../dto/template-dto';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, firstValueFrom, map, Observable, startWith } from 'rxjs';
import Swal from 'sweetalert2';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ColorPickerModule } from 'ngx-color-picker';
import { TemplateTopicDto } from '../dto/template-topic-dto';
import { MatButtonModule } from '@angular/material/button';

export interface LabelContent {
    name: string;
    value: number;
}

export interface TopicOption {
    name: string;
    topicRef: string;
}

@Component({
    selector: 'app-manage-template',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        ColorPickerModule,
        FormsModule,
        MatSelectModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        // Template3x3Component,
        ReactiveFormsModule,
        RouterLink,
        NgIf,
        AsyncPipe
    ],
    templateUrl: './manage-template.component.html',
    styleUrl: './manage-template.component.scss',
})
export class ManageTempateComponent implements OnInit {

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {

        }
    }

    http = inject(HttpClient);

    template: TemplateDto;
    templateForm: FormGroup;
    promptForm: FormGroup;
    headerForm: FormGroup;

    color: any;

    formMode: 'add' | 'update';
    id!: string | null;

    bodyColumns: number = 3;
    bodyRows: number = 3;
    bodyMatrix: number = this.bodyColumns * this.bodyRows;
    bodyMatrixSize: number[] = [];

    selectedMatrix: string = "1";
    dtCardMatrixSize: number[] = [];

    // Select Value
    statusSelected = 'option1';
    matrixSelectedOption = '1';
    labelSelectedOption2 = 'option2';
    labelSelectedOption3 = 'option3';
    labelSelectedOption4 = 'option4';
    labelSelectedOption5 = 'option5';
    labelSelectedOption6 = 'option6';
    labelSelectedOption7 = 'option7';
    labelSelectedOption8 = 'option8';
    labelSelectedOption9 = 'option9';

    collapsedInformation: boolean = false;
    collapsedHeader: boolean = false;
    collapsedBody: boolean = false;
    collapsedDTCard: boolean = false;
    collapsedPrompt: boolean = false;

    // Display Value
    topicControl = new FormControl<string | TopicOption>('');
    topicOptions: TopicOption[] = [];
    // filteredOptions!: Observable<TopicOption[]>;

    options = [
        { name: 'Option 1' },
        { name: 'Option 2' },
        { name: 'Option 3' },
    ]; // ตัวเลือกทั้งหมด
    filteredOptions: BehaviorSubject<Observable<any[]>[]> = new BehaviorSubject<Observable<any[]>[]>([]);

    // filteredOptions: BehaviorSubject<Observable<TopicOption[]>[]> = new BehaviorSubject<Observable<TopicOption[]>[]>([]);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
        this.fetchTopicData();
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {

        }
        // debugger;
        this.id = this.activatedRoute.snapshot.paramMap.get('id');

        this.formMode = this.id ? 'update' : 'add';

        this.templateForm = this.formBuilder.group({
            // id: [''],
            name: ['', [Validators.required]],
            description: [''],
            promptAiGenAll: [''],
            promptAiGenBodyToTop: [''],
            promptAiGenTopToBody: [''],
            promptAiGenDTCard: [''],
            // topics: this.formBuilder.group(new TemplateTopicDto()),
            topics: this.formBuilder.array([])
            // isActive: [true]
        });

        // const templateTopics = [
        //     { topicName: 'Topic 1', bgColor: '#f0f0f0', fontColor: '#333333' },
        //     { topicName: 'Topic 2', bgColor: '#ffffff', fontColor: '#000000' },
        // ];

        // const topicFormGroups = templateTopics.map(topic =>
        //     this.formBuilder.group({
        //         topicName: [topic.topicName || ''],
        //         bgColor: [topic.bgColor || '#ffffff'],
        //         fontColor: [topic.fontColor || '#000000'],
        //     })
        // );

        // const topicFormArray = this.formBuilder.array(topicFormGroups);
        // this.templateForm = this.formBuilder.group({
        //     templateTopics: topicFormArray,
        // });

        // // Set up filteredOptions for each topic
        // this.setupFilteredOptions();

        if (this.id) {
            this.fetchData();

            this.onBodyColumnMatrixChange();
            this.onMatrixChange();
        } else {
            this.templateForm.patchValue({ isDTCard: true })
        }
    }

    get topicFormArray(): FormArray {
        const topics = this.templateForm.get('topics') as FormArray;

        console.log(topics)

        return topics;
    }

    

    private filterOptions(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onClickSelectTopic() {
        console.log('hi')
        // this.topicOptions = res;
        this.topicControl.patchValue('')
    }

    bgColorChange(id: string, $event: any) {
        const findItem = this.template.topics?.find(item => item.id === id);
        // console.log(findItem)
        if (findItem) {
            findItem.bgColor = $event.value;
        }
        // console.log($event.value)
    }

    fontColorChange(id: string, $event: any) {
        const findItem = this.template.topics?.find(item => item.id === id);
        // console.log(findItem)
        if (findItem) {
            findItem.fontColor = $event.value;
        }
        // console.log($event.value)
    }

    // getControlValue(group: FormGroup, controlName: string): any {
    //     return group.get(controlName)?.value || '';
    //   }

    getControlValue(ctl: any, fieldName: string): string {
        // console.log(ctl)
        return ctl.controls[fieldName]?.value || '';
    }

    // get topicFormArray(): FormArray{
    //     const frm = this.templateForm.get('templateTopics') as FormArray;
    //     // console.log(frm)
    //     return frm;
    // }

    // addABCTopics() {
    //     const topics = this.headerForm.controls['headerTopics'] as FormArray;
    //     topics.push(this.formBuilder.group({
    //       name: ''
    //     }));
    //   }

    displayFn(topic: TopicOption): string {
        return topic && topic.name ? topic.name : '';
    }
    private _filter(name: string): TopicOption[] {
        const filterValue = name.toLowerCase();
        return this.topicOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onBodyColumnMatrixChange() {
        // console.log(this.bodyColumns);

        this.bodyMatrix = this.bodyColumns * this.bodyRows;

        this.bodyMatrixSize = [];
        for (var i = 1; i <= this.bodyMatrix; i++) {
            this.bodyMatrixSize.push(i);
        }

        // console.log(this.bodyMatrixSize)
    }

    onBodyRowMatrixChange() {
        // console.log(this.bodyRows);

        this.bodyMatrix = this.bodyColumns * this.bodyRows;

        this.bodyMatrixSize = [];
        for (var i = 1; i <= this.bodyMatrix; i++) {
            this.bodyMatrixSize.push(i);
        }

        // console.log(this.bodyMatrixSize)
    }

    onMatrixChange() {
        // console.log(this.selectedMatrix);//
        if (this.selectedMatrix == "1") {
            this.dtCardMatrixSize = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
        }
        else {
            this.dtCardMatrixSize = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
        }
    }

    genPromptAll() {
        console.log('genPromptAll()')
        this.snackBar.open('genPromptAll successful.', 'OK', {
            duration: 3000,
            panelClass: ['notification-error'],
        });
    }

    genPromptTopToBody() {
        console.log('genPromptTopToBody()')
        this.snackBar.open('genPromptTopToBody successful.', 'OK', {
            duration: 3000,
            panelClass: ['notification-error'],
        });
    }

    genPromptBodyToTop() {
        console.log('genPromptBodyToTop()')
        this.snackBar.open('genPromptBodyToTop successful.', 'OK', {
            duration: 3000,
            panelClass: ['notification-error'],
        });
    }

    genPromptDtCard() {
        console.log('genPromptDtCard()')
        this.snackBar.open('genPromptDtCard successful.', 'OK', {
            duration: 3000,
            panelClass: ['notification-error'],
        });
    }

    async fetchTopicData() {
        // const topics$ = this.http.get(`${environment.apiUrl}/api/v1/topics`);
        // const topics = await firstValueFrom(topics$);
        this.topicOptions = [];

        this.http
            .get(`${environment.apiUrl}/api/v1/topics`)
            .subscribe((res: any) => {
                this.topicOptions = res;
                this.topicControl.patchValue('')
                // console.log(this.topicOptions);
            });
    }

    async fetchData() {
        // const dto$ = this.http.get(`${environment.apiUrl}/api/v1/templates/${this.id}`);
        // const dto = await firstValueFrom(dto$);

        this.http
            .get(`${environment.apiUrl}/api/v1/templates/${this.id}`)
            .subscribe((res: any) => {
                console.log(res)


                this.template = res;
                const template = this.template;

                this.templateForm.patchValue({
                    id: template.id,
                    name: template.name,
                    description: template.description,
                    promptAiGenAll: template.promptAiGenAll,
                    promptAiGenBodyToTop: template.promptAiGenBodyToTop,
                    promptAiGenTopToBody: template.promptAiGenTopToBody,
                    promptAiGenDTCard: template.promptAiGenDTCard,
                });

                // let topicFormGroups = template.topics?.map((topic: any) => this.formBuilder.group({
                //     topicName: [topic.topicName || ''], // Default or existing value
                //     bgColor: [topic.bgColor || '#ffffff'], // Default color
                //     fontColor: [topic.fontColor || '#000000'], // Default color
                // }));
                let topicFormGroups = template.topics?.map((topic: any) => this.formBuilder.group(topic));
                if(topicFormGroups) {
                    let topicFormArray = this.formBuilder.array(topicFormGroups);
                    this.templateForm.setControl('topics', topicFormArray);

                    console.log(this.templateForm)
                }
            });

        // console.log(dto)
        // this.templateForm.patchValue({
        //     id: dto.
        // });

        // this.promptForm.patchValue(dto);
    }

    async create(): Promise<boolean> {
        const dtoValue: TemplateDto = this.templateForm.getRawValue();
        // console.log(dtoValue)
        const dto$ = this.http.post(`${environment.apiUrl}/api/v1/templates`, dtoValue);

        const dto = await firstValueFrom(dto$)

        return dto ? true : false;
    }

    async update(): Promise<boolean> {
        const dtoValue: TemplateDto = this.templateForm.getRawValue();

        // dtoValue.promptAiGenAll = this.promptForm.controls['promptAiGenAll'].value;
        // dtoValue.promptAiGenTopToBody = this.promptForm.controls['promptAiGenTopToBody'].value;
        // dtoValue.promptAiGenBodyToTop = this.promptForm.controls['promptAiGenBodyToTop'].value;
        // dtoValue.promptAiGenDTCard = this.promptForm.controls['promptAiGenDTCard'].value;

        console.log(dtoValue)

        if(dtoValue.topics)
            delete dtoValue.topics;

        const dto$ = this.http.put(`${environment.apiUrl}/api/v1/templates/${this.id}`, dtoValue);
        const dto = await firstValueFrom(dto$);

        return dto ? true : false;
    }

    async save() {
        if (this.templateForm.valid) {
            if (this.formMode == 'add') {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // const dtoForSave = this.dtoForm.getRawValue();
                        // console.log(dtoForSave)
                        if (await this.create()) {
                            Swal.fire({
                                title: "Saved successfully",
                                text: "Your data has been saved.",
                                icon: "success"
                            }).then(async (result) => {
                                if (result) {
                                    this.router.navigate(["topic-management-page/topics"]);
                                }
                            });
                        }
                    }
                });
            } else {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const dtoForSave = this.templateForm.getRawValue();
                        // console.log(dtoForSave)
                        if (await this.update()) {
                            Swal.fire({
                                title: "Update successfully",
                                text: "Your data has been updated.",
                                icon: "success"
                            });
                        }
                    }
                });
            }
        }
    }

    labels: LabelContent[] = [
        { name: 'Null', value: 0 },
        { name: 'Core products & services', value: 1 },
        { name: 'Performance', value: 2 },
        { name: 'Experience', value: 3 },
        { name: 'Corporate admin. & back office', value: 4 },
        { name: 'Laws, compliance & audit', value: 5 },
        { name: 'Data', value: 6 },
        { name: 'Platform technology', value: 7 },
        { name: 'Monitoring technology', value: 8 },
        { name: 'Security technology', value: 9 },
    ];
}
