<!-- Breadcrumb -->
<!-- <div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">

    <h5 class="mb-0">
        Sites
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Apps
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Sites
        </li>
    </ol>
</div> -->

<!-- Profile -->
<!-- <mat-card class="daxa-card profile-card mb-20 p-0 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
    <mat-card-content>

        <div class="cover-image position-relative border-top-radius">
            <div class="row">
                <div class="col-lg-6">
                    <div class="content">
                        <span class="d-block text-white">
                            xx
                        </span>
                        <h3 class="text-blue">
                            Welcome To Daxa
                        </h3>
                    </div>
                </div>
            </div>
            <img src="images/profile-big-cover.jpg" class="border-top-radius" alt="cover-image">
            <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editCoverPhotoMenu">
                Switch site
            </button>
            <mat-menu #editCoverPhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                <button mat-menu-item>
                    Site A
                </button>
                <button mat-menu-item>
                    Site B
                </button>
                <button mat-menu-item>
                    Site C
                </button>
            </mat-menu>
        </div>
        <div class="profile-content">
            <div class="d-md-flex align-items-end justify-content-between">
                <div class="d-md-flex align-items-end">
                    <div class="image position-relative">
                        <img src="images/profile.jpg" class="rounded-circle" alt="profile-image">
                        <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editProfilePhotoMenu">
                            <i class="material-symbols-outlined">
                                photo_camera
                            </i>
                        </button>
                        <mat-menu #editProfilePhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                            <button mat-menu-item>
                                See Photo
                            </button>
                            <button mat-menu-item>
                                Upload Photo
                            </button>
                            <button mat-menu-item>
                                Remove
                            </button>
                        </mat-menu>
                    </div>
                    <div class="title">
                        <span class="d-block fw-medium">
                            Welcome to
                        </span>
                        <span class="d-block text-body">
                            [ {{site?.name}} ]
                            Digital Technology Realized Platform
                        </span>
                    </div>
                </div>
                <div class="btn-box">
                    <button mat-button title="Invite link" disabled
                        (click)="openSnackBar('Generate invitation link successful.', 'OK')">
                        <i class="material-symbols-outlined" style="font-size: small;">
                            share
                        </i> Share
                    </button>
                    <button mat-button title="Switch site" disabled [matMenuTriggerFor]="editCoverPhotoMenu">
                        <i class="material-symbols-outlined" style="font-size: medium;">
                            swap_horiz
                        </i> Site
                    </button>
                    <button mat-button title="Manage members" routerLink="/members">
                        <i class="material-symbols-outlined" style="font-size: medium;">
                            groups
                        </i> Members
                    </button>
                    <mat-menu #editCoverPhotoMenu="matMenu" xPosition="after">
                        <button mat-menu-item>
                            Default
                        </button>
                        <button mat-menu-item>
                            Site 01
                        </button>
                        <button mat-menu-item>
                            Site 02
                        </button>
                    </mat-menu>

                </div>
            </div>
            <ul class="nav-links text-center pl-0 mb-0 list-unstyled">
                <li class="d-inline-block">
                    <a routerLink="/profile" class="d-block position-relative" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        Profile
                    </a>
                </li>
                <li class="d-inline-block">
                    <a routerLink="/profile/teams" class="d-block position-relative" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        Team
                    </a>
                </li>
                <li class="d-inline-block">
                    <a routerLink="/profile/projects" class="d-block position-relative" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        Projects
                    </a>
                </li>
            </ul>
        </div>
    </mat-card-content>
</mat-card> -->

<div class="row">
    <div class="col-xxxl-12">
        <mat-card class="daxa-card chat-sidebar-card mb-20 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-content>
                <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="mt-10">
                        <mat-label>
                            Select site
                        </mat-label>
                        <mat-select [(value)]="siteId">
                            <mat-option value="22677c31-c3ed-4206-be2f-83abe533e645">
                                Demo site
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="mt-10">
                        <mat-label>
                            Select workspace
                        </mat-label>
                        <mat-select [(value)]="siteId">
                            <mat-option value="22677c31-c3ed-4206-be2f-83abe533e645">
                                โรงงานผลิตกาแฟ
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-xxxl-12">
        <mat-card class="daxa-card chat-sidebar-card mb-20 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Workspaces
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <a mat-button class="add-new-btn" [matMenuTriggerFor]="cardHeaderMenu">
                        + Add
                    </a>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        @for (template of site?.templates; track template)
                        {
                            <button mat-menu-item (click)="addWorkspace('template.id')">
                               {{template.name}}
                            </button>
                        }
                        <!-- <button mat-menu-item (click)="addWorkspace('3x3')">
                            3x3 Workspace
                        </button>
                        <button mat-menu-item>
                            4x4 Workspace
                        </button> -->
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="chat-users-list">
                    <!-- <div class="single-user-item active d-flex align-items-center justify-content-between"> -->
                    <!-- (click)="gotoWorkspace('01')" -->

                    @for (workspace of site?.workspaces; track workspace)
                    {
                    <div class="single-user-item d-flex align-items-center justify-content-between"
                        routerLinkActive="router-link-active">
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title" title="Go to workspace" (click)="gotoWorkspace(workspace.id)"> <!-- routerLink="/site-workspace/workspace" -->
                                <span class="d-block">
                                    {{workspace.name}}
                                </span>
                                <!-- <span class="d-block text-body">
                                    20 members
                                </span> -->
                            </div>
                        </div>
                        <div class="info">
                            <button type="button" mat-button class="card-header-menu-btn p-0"
                                [matMenuTriggerFor]="workspaceMenu">
                                <i class="material-symbols-outlined">
                                    more_horiz
                                </i>
                            </button>
                            <mat-menu #workspaceMenu="matMenu" class="card-header-menu" xPosition="before">
                                <button mat-menu-item  (click)="onEditWorkspaceName(workspace)">
                                    <i class="material-symbols-outlined" style="font-size: medium;">
                                        edit
                                    </i>
                                    Renane
                                </button>
                                <button mat-menu-item class="text-body">
                                    Make a copy
                                </button>
                                <button mat-menu-item class="text-body">
                                    Delete
                                </button>
                                <button mat-menu-item class="text-body">
                                    Share to others
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    }

                    <!-- <div class="single-user-item d-flex align-items-center justify-content-between">
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user2.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    2. งานที่2 (Performance Workspace 02)
                                </span>
                                <span class="d-block text-body">
                                    19 members
                                </span>
                            </div>
                        </div>
                        <div class="info">
                            <button type="button" mat-button class="card-header-menu-btn p-0"
                                [matMenuTriggerFor]="workspaceMenu">
                                <i class="material-symbols-outlined">
                                    more_horiz
                                </i>
                            </button>
                            <mat-menu #workspaceMenu="matMenu" class="card-header-menu" xPosition="before">
                                <button mat-menu-item>
                                    Renane
                                </button>
                                <button mat-menu-item>
                                    Make a copy
                                </button>
                                <button mat-menu-item>
                                    Delete
                                </button>
                                <button mat-menu-item>
                                    Share to others
                                </button>
                            </mat-menu>
                        </div>
                    </div> -->
                    <!-- <div class="single-user-item d-flex align-items-center justify-content-between">
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user2.jpg" class="rounded-circle" alt="user-image">
                                <span class="deactive-status"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    3. งานที่3 (Performance Workspace 03)
                                </span>
                                <span class="d-block text-body">
                                    50 members
                                </span>
                            </div>
                        </div>
                        <div class="info">
                            <button type="button" mat-button class="card-header-menu-btn p-0"
                                [matMenuTriggerFor]="workspaceMenu">
                                <i class="material-symbols-outlined">
                                    more_horiz
                                </i>
                            </button>
                            <mat-menu #workspaceMenu="matMenu" class="card-header-menu" xPosition="before">
                                <button mat-menu-item>
                                    Renane
                                </button>
                                <button mat-menu-item>
                                    Make a copy
                                </button>
                                <button mat-menu-item>
                                    Delete
                                </button>
                                <button mat-menu-item>
                                    Share to others
                                </button>
                            </mat-menu>
                        </div>
                    </div> -->
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<router-outlet />