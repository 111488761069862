<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Sites
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Super Admin
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Management
        </li>
        <li class="breadcrumb-item position-relative">
            Sites
        </li>
    </ol>
</div>

<!-- Sites List -->
<mat-card class="daxa-card products-list-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()">
    <mat-card-header>
        <mat-card-title>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search site..."
                    (keyup)="applyFilter($event)" #input>
            </form>
            <!-- <ul class="info pl-0 mb-0 mt-0 list-unstyled text-body">
                <li class="d-inline-block">
                    All Products <span class="text-daxa">(48,753)</span>
                </li>
                <li class="d-inline-block">
                    Published Products <span class="text-daxa">(35,921)</span>
                </li>
                <li class="d-inline-block">
                    Drafts Products <span class="text-daxa">(12,832)</span>
                </li>
            </ul> -->
        </mat-card-title>
        <mat-card-subtitle>


            <ul class="info pl-0 mb-0 mt-0 list-unstyled text-body">
                <li class="d-inline-block">
                    <a mat-button class="add-new-btn" routerLink="/site-management-page/manage" disabled>
                        <i class="material-symbols-outlined" style="font-size: medium;">
                            add_business
                        </i>&nbsp;Add Site
                    </a>
                </li>
                <li class="d-inline-block">

                    <a mat-button class="add-new-btn" routerLink="/site-management-page/manage" disabled>
                        <i class="material-symbols-outlined" style="font-size: medium;">
                            upload
                        </i>&nbsp;Import Site
                    </a>
                </li>
            </ul>
            <!-- <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                + Add New Site
            </button> -->
            <!-- <a mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                + Add New Site
            </a>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item (click)="addWorkspace('3x3')">
                    3x3 Workspace
                </button>
                <button mat-menu-item>
                    4x4 Workspace
                </button>
            </mat-menu> -->
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="products-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <!-- <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container> -->

                    <!-- Product ID Column -->
                    <!-- <ng-container matColumnDef="siteId">
                        <th mat-header-cell *matHeaderCellDef>
                            Site ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.siteId}}
                        </td>
                    </ng-container> -->

                    <!-- Product Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="info d-flex align-items-center">
                                <!-- <div class="image">
                                    <img [src]="element.site.img" alt="product-image">
                                </div> -->
                                <a routerLink="/site-management-page/{{element.id}}" class="d-block fw-medium">
                                    {{element.name}}
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Stock Quantity Column -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>
                            Description
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.description}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="maxDTCard">
                        <th mat-header-cell *matHeaderCellDef>
                            Max DT Card
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.maxDTCard}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <!-- <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge active d-inline-block" *ngIf="element.status.active">
                                {{element.status.active}}
                            </span>
                            <span class="daxa-badge deactive d-inline-block" *ngIf="element.status.deactive">
                                {{element.status.deactive}}
                            </span>
                        </td>
                    </ng-container> -->

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <a mat-button (click)="openSnackBar('Generate invitation link successful.', 'OK')" 
                                    matTooltip="Share" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        share
                                    </i>
                                </a>
                                <a mat-button routerLink="/site-management-page/edit-site" matTooltip="Edit" disabled
                                    matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        edit
                                    </i>
                                </a>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above" disabled>
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Download" matTooltipPosition="above" disabled>
                                    <i class="material-symbols-outlined">
                                        download
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>