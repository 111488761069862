import { NgIf } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomizerSettingsService } from '../../../customizer-settings/customizer-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteDto } from '../dto/site-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-list-site',
    standalone: true,
    imports: [
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        MatTableModule,
        MatPaginatorModule,
        NgIf,
        MatCheckboxModule,
        MatTooltipModule,
    ],
    templateUrl: './list-site.component.html',
    styleUrl: './list-site.component.scss',
})
export class ListSiteComponent implements OnInit, AfterViewInit  {
    displayedColumns: string[] = [
        'name',
        'description',
        'maxDTCard',
        // 'status',
        'action',
    ];
    dataSource = new MatTableDataSource<SiteDto>([]);
    selection = new SelectionModel<SiteDto>(true, []);

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SiteDto): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
            //row.product + 1
            1
        }`;
    }

    // Search Filter
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    // isToggled
    isToggled = false;

    http = inject(HttpClient);

    siteList: SiteDto[] = [];

    constructor(
        public themeService: CustomizerSettingsService,
        private _snackBar: MatSnackBar
    ) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
    }
    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        // debugger;
        this.http
            .get(`${environment.apiUrl}/api/v1/sites`)
            .subscribe((res: any) => {
                this.siteList = res;
                console.log(this.siteList);
                this.dataSource.data = this.siteList;
            });
    }

    addWorkspace(template: String) {
        console.log(template);
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action);
    }
}

// const ELEMENT_DATA: PeriodicElement[] = [
//     {
//         siteId: '#001',
//         site: {
//             img: 'images/products/product6.png',
//             name: 'Site 01',
//         },
//         maxMember: 5000,
//         maxDTCard: 5000,
//         description: '',
//         status: {
//             active: 'Active',
//             // deactive
//         },
//         action: {
//             share: 'share',
//             edit: 'edit',
//             delete: 'delete',
//             download: 'download',
//         },
//     },
//     {
//         siteId: '#002',
//         site: {
//             img: 'images/products/product3.png',
//             name: 'Site 02',
//         },
//         maxMember: 5000,
//         maxDTCard: 5000,
//         description: '',
//         status: {
//             active: 'Active',
//             // deactive
//         },
//         action: {
//             share: 'share',
//             edit: 'edit',
//             delete: 'delete',
//             download: 'download',
//         },
//     },
//     {
//         siteId: '#003',
//         site: {
//             img: 'images/products/product8.png',
//             name: 'Site 03',
//         },
//         maxMember: 5000,
//         maxDTCard: 5000,
//         description: '',
//         status: {
//             active: 'Active',
//             // deactive
//         },
//         action: {
//             share: 'share',
//             edit: 'edit',
//             delete: 'delete',
//             download: 'download',
//         },
//     },
// ];
// export interface PeriodicElement {
//     siteId: string;
//     site: any;
//     maxMember: number;
//     maxDTCard: number;
//     description: string;
//     status: any;
//     action: any;
// }
